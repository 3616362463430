import React, { useState, useEffect, memo } from 'react';
import { Layout, Button, Space, Switch, Dropdown } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ArrowDownOutlined,
	PrinterFilled,
	ExportOutlined,
	UserOutlined,
	DownOutlined,
	FileExcelOutlined,
	FileTextOutlined,
	FileAddOutlined
} from '@ant-design/icons';

import SubHeader from '../Common/SubHeader';
import FilterForAdd from './FilterForAdd';
import QuestionTable from './QuestionTable';
import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';
import {
	addFilters,
	getQuestion,
	questionExportRequest
} from '../../stateManager/reducers/questionSlice';
import { saveAs } from 'file-saver';
import htmlToDocx from 'html-to-docx';
const { Content } = Layout;

const CourseWiseQuestion = memo((props) => {
	console.log('CourseWiseQuestion props', props);
	const { courseId } = props.match?.params || props;
	const [examView, setExamView] = useState(false);
	const [printAnswer, setPrintAnswer] = useState(false);
	const [printH,setPrintH] = useState(false)
	const [withExplanation, setWithExplanation] = useState(true);
	const dispatch = useDispatch();
	const history = useHistory();
	const [showLoadMore, setShowLoadMore] = useState(true);
	const selectedSubjectForFilter = useSelector(
		state => state.questions.selectedSubjectForFilter
	);
	const selectedChapterForFilter = useSelector(
		state => state.questions.selectedChapterForFilter
	);
	const selectedLectureForFilter = useSelector(
		state => state.questions.selectedLectureForFilter
	);

	const selectedQuestionSolveForFilter = useSelector(
		state => state.questions.selectedQuestionSolveForFilter
	);

	const selectedCourse = JSON.parse(localStorage.getItem('selectedCourse'));
	const subjectList = useSelector(state => state.courses.subjectList);
	const questionList = useSelector(
		state => state.questions.questionBySubjectCourse
	);

	const handleExportButtonClick = (e) => {
		if(e?.key == '1'){
			dispatch(
				questionExportRequest({
					courseId,
					subjectId: selectedSubjectForFilter,
					lectureId: selectedLectureForFilter,
					chapterId: selectedChapterForFilter
				})
			)
		}else if(e?.key == '1'){

		}else if(e?.key == '1'){
			
		}
  		console.log('click', e);
	}

	const dropdownItems = [
		{
		  label: 'EXCEL',
		  key: '1',
		  icon : <FileExcelOutlined />,
		  onClick: handleExportButtonClick
		},
		{
		  label: 'CSV',
		  key: '2',
		  icon : <FileAddOutlined />,
		  onClick: handleExportButtonClick
		},
		{
		  label: 'DOC',
		  key: '3',
		  icon : <FileTextOutlined />,
		  onClick: handleExportButtonClick
		},
	];

	useEffect(() => {
		if(printAnswer){
			
			window.print()
			setPrintAnswer(false);
		}
	}, [printAnswer]);

	// useEffect(()=>{
	// 	const questionDetailPageReload = localStorage.getItem('qDetailsReload')
	// 	if(!questionDetailPageReload){
	// 		localStorage.setItem('qDetailsReload','true')

	// 		setTimeout(() => {
	// 			window.location.reload('qDetailsReload')
	// 		}, 100);
	// 	}else{
	// 		setTimeout(() => {
	// 			localStorage.removeItem('qDetailsReload')
	// 		}, 500);
	// 	}
	// },[])

	useEffect(()=>{
		if(printH){
			window.print()
							setPrintAnswer(true)
			setTimeout(() => {
				setPrintH(false)
			}, 500);
			
		}
		
	},[printH])
	useEffect(() => {
		console.log('CourseWiseQuestion courseId', courseId);
		async function fetchSub() {
			await dispatch(getSubjectByCourse(courseId));
			const historyAction = history.action;
			if (historyAction === 'PUSH') {
				dispatch(
					addFilters({ key: 'selectedSubjectForFilter', value: undefined })
				);
				dispatch(
					addFilters({ key: 'selectedLectureForFilter', value: undefined })
				);
				dispatch(
					addFilters({ key: 'selectedChapterForFilter', value: undefined })
				);
				dispatch(
					addFilters({ key: 'selectedQuestionSolveForFilter', value: undefined })
				);
			}
		}
		fetchSub();
	}, [courseId, dispatch, history]);

	useEffect(() => {
		async function fetchData() {
			await dispatch(
				getQuestion({
					courseId,
					lectureId: selectedLectureForFilter,
					chapterId: selectedChapterForFilter,
					subjectId: selectedSubjectForFilter,
					questionSolveId: selectedQuestionSolveForFilter
				})
			);
		}
		fetchData();
	}, [
		selectedSubjectForFilter,
		selectedLectureForFilter,
		selectedChapterForFilter,
		selectedQuestionSolveForFilter,
		courseId,
		dispatch
	]);

	const handleLoadMore = async () => {
		const isLoadMore = true;
		const lastId =
			questionList && questionList.length > 0
				? questionList[questionList.length - 1]._id
				: undefined;
		const res = await dispatch(
			getQuestion({
				courseId,
				lectureId: selectedLectureForFilter,
				chapterId: selectedChapterForFilter,
				subjectId: selectedSubjectForFilter,
				questionSolveId: selectedQuestionSolveForFilter,
				lastId,
				isLoadMore
			})
		);
		if (res && res?.payload?.data && res?.payload?.data?.length !== 30) {
			setShowLoadMore(false);
		}
	};

	const getQuestionByTitle = async title => {
		await dispatch(getQuestion({ courseId, title }));
	};

	return (
		<React.Fragment>
			<SubHeader
				headText={selectedCourse?.name || 'Not given'}
				backIcon={true}
				backTo='/question-bank'
			/>
			<div
				className='site-layout-background'
				style={{
					padding: 15,
					paddingTop: 0,
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
			>
				<p>Add, view and edit questions</p>
				<div style={{
							marginRight: '20px',
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'row',
							rowGap: '10px',
							gap: '20px'

						}} >
						<div>
						With Explanation
						<Switch
						unCheckedChildren='False'
						checkedChildren='True'
						checked={withExplanation}
						style={{
							marginLeft: '10px'
						}}
						onChange={(checked) => {
							setWithExplanation(checked)
						}}

						
					/>
						</div>
						<div>
						Exam View
						<Switch
						unCheckedChildren='False'
						checkedChildren='True'
						checked={examView}
						style={{
							marginLeft: '10px'
						}}
						onChange={(checked) => {
							setExamView(checked)
						}}
						
					/>
						</div>
					</div>

				<Space>
					{/* <Button
						size='large'
						type='primary'
						style={{ margin: '10px' }}
						icon={<ExportOutlined />}
						onClick={() =>
							dispatch(
								questionExportRequest({
									courseId,
									subjectId: selectedSubjectForFilter,
									lectureId: selectedLectureForFilter,
									chapterId: selectedChapterForFilter
								})
							)
						}
					>
						Export Questions
					</Button> */}

					<Dropdown menu={{items: dropdownItems}}>
						<Button size='large' type='primary' style={{ margin: '10px' }} >
							<Space>
								Export Question As
							<DownOutlined />
							</Space>
						</Button>
					</Dropdown>
					
					

					<Button
						size='large'
						type='primary'
						icon={<PrinterFilled />}
						style={{ margin: '10px' }}
						onClick={() => {
							setPrintH(true)
							
							
							
						}}
					>
						Print
					</Button>
					
				</Space>
			</div>

			<FilterForAdd
				subjectList={subjectList}
				courseId={courseId}
				getQuestionByTitle={getQuestionByTitle.bind(this)}
			/>

			<Content className={`exam-details-page teachers-view course-wise-que-table print-teacher`}>
				<QuestionTable data={questionList} examView={examView} withExplanation={withExplanation} printH={printH} printAnswer={printAnswer} showLoadMore={showLoadMore} />
			</Content>

			{showLoadMore && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignContent: 'center'
					}}
				>
					<Button onClick={() => handleLoadMore()} type='link'>
						Load More <ArrowDownOutlined />
					</Button>
				</div>
			)}
		</React.Fragment>
	);
});

export default CourseWiseQuestion;
