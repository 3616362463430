import React, { useEffect } from 'react';
import { Row, Col, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
	getChapterBySubject,
	getLectureBySubject,
	getQuestionSolveBySubject
} from '../../stateManager/reducers/courseSlice';
import { updateLocalStorage, getLocalStorageData } from '../../constants/constFunction';
import { LOCAL_KEY } from '../../constants/constString';
import { use } from 'react';

const { Option } = Select;

const FilterForAdd = props => {
	const {
		subjectList,
		selectedChapter,
		selectedLecture,
		selectedSubject,
		selectedQuestionSolve,
		contentName
	} = props;
	const dispatch = useDispatch();
	const chapterList = useSelector(state => state.courses.chapterList);
	const lectureList = useSelector(state => state.courses.lectureList);
	const questionSolveList = useSelector(
		state => state.courses.questionSolveList
	);

	useEffect(() => {
		const { selectedSubject, selectedLecture, selectedChapter, selectedQuestionSolve } = getLocalStorageData(LOCAL_KEY.VIDEO_LECTURE_KEY);
		if (selectedSubject) {
			props.toggleSelectSubject(selectedSubject);
			props.toggleSelectLecture(selectedLecture);
			props.toggleSelectChapter(selectedChapter);
			props.toggleSelectQuestionSolve(selectedQuestionSolve);
			handleSubjectChange(selectedSubject, false);
		}
	}, []);

	const handleSubjectChange = async (subjectId, init = true) => {
		await dispatch(getLectureBySubject({ subjectId }));
		await dispatch(getChapterBySubject({ subjectId }));
		await dispatch(getQuestionSolveBySubject({ subjectId }));
		props.toggleSelectSubject(subjectId);
		if (init) {
			props.toggleSelectChapter(undefined);
			props.toggleSelectLecture(undefined);
			props.toggleSelectQuestionSolve(undefined);
		}

		
	};



	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[8, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
				justify='space-between'
			>
				<Col xs={24} sm={24} md={3} lg={5}>
					Select subject and lecture to add{' '}
					{contentName === 'file' ? 'note' : 'video'}
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<strong>Select Subject</strong>
					<Select
						onChange={value => {
							updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'selectedSubject', value);
							handleSubjectChange(value)
						}}
						style={{ width: '100%' }}
						value={selectedSubject}
						placeholder='Select Subject'
					>
						{subjectList &&
							subjectList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<strong>Select Lecture</strong>
					<Select
						onChange={value => {
							props.toggleSelectLecture(value);
							updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'selectedLecture', value);
						}}
						value={selectedLecture}
						style={{ width: '100%' }}
						placeholder='Select Lecutre'
					>
						{lectureList &&
							lectureList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<strong>Select Chapter</strong>
					<Select
						onChange={value => {
							props.toggleSelectChapter(value);
							updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'selectedChapter', value);
						}}
						style={{ width: '100%' }}
						placeholder='Select Chapter'
						value={selectedChapter}
					>
						{chapterList &&
							chapterList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={6} lg={4}>
					<strong>Select Question Solve</strong>
					<Select
						onChange={value => {
							props.toggleSelectQuestionSolve(value);
							updateLocalStorage(LOCAL_KEY.VIDEO_LECTURE_KEY, 'selectedQuestionSolve', value);
						}}
						style={{ width: '100%' }}
						placeholder='Select Question Solve'
						value={selectedQuestionSolve}
					>
						{questionSolveList &&
							questionSolveList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={6} lg={2}>
					<Button
						disabled={
							!selectedSubject ||
							(!selectedChapter && !selectedLecture && !selectedQuestionSolve)
						}
						onClick={() => props.toogleAddVideoModal()}
						type='primary'
					>
						{contentName === 'file' ? 'Add Note' : 'Add Video'}
					</Button>
					{
						contentName === 'video' &&	
						<Button
						disabled={
							!selectedSubject ||
							(!selectedChapter && !selectedLecture && !selectedQuestionSolve)
						}
						onClick={() => props.toogleUploadVideoModal()}
						type='primary'
					>
						Upload Video
					</Button>
					}
					
				</Col>
			</Row>
		</div>
	);
};

export default FilterForAdd;
