import React, { useEffect, useState } from 'react';
import { Layout, Divider } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import SubHeader from '../Common/SubHeader';
import FilterForAdd from './FilterForAdd';
import AddVideoModal from './AddVideoModal';
import UploadVideoModal from './UploadVideoModal';
import VideoListTable from './VideoListTable';

import { getSubjectByCourse } from '../../stateManager/reducers/courseSlice';
import { LOCAL_KEY } from '../../constants/constString';
import { getLocalStorageData, updateLocalStorage } from '../../constants/constFunction';
import {
	reorderContent,
	getChapterLectureById
} from '../../stateManager/reducers/contentSlice';

const { Content } = Layout;

const CourseWiseVideoList = props => {
	const [isAddVideoModalVisible, setVideoModalVisible] = useState(false);
	const [isUploadVideoModalVisible, setUploadVideoModalVisible] = useState(false);	
	const [selectedLecture, setSelectedLecture] = useState(undefined);
	const [selectedChapter, setSelectedChapter] = useState(undefined);
	const [selectedQuestionSolve, setSelectedQuestionSolve] = useState(undefined);
	const [selectedSubject, setSelectedSubject] = useState(undefined);

	const { courseId } = props.match.params;
	const dispatch = useDispatch();
	const subjectList = useSelector(state => state.courses.subjectList);
	const course = JSON.parse(localStorage.getItem('selectedCourse'));
	const { content } = getLocalStorageData(LOCAL_KEY.VIDEO_LECTURE_KEY);
	const contentName = content || 'video';
	const videoList = useSelector(state =>
		contentName === 'video' ? state.contents.videoList : state.contents.fileList
	);

	const toogleAddVideoModal = () =>
		setVideoModalVisible(!isAddVideoModalVisible);

	const toogleUploadVideoModal = () =>
		setUploadVideoModalVisible(!isUploadVideoModalVisible);

	const toggleSelectChapter = value => {
		setSelectedChapter(value);
		setSelectedLecture(undefined);
		setSelectedQuestionSolve(undefined);
	};
	const toggleSelectLecture = value => {
		setSelectedChapter(undefined);
		setSelectedQuestionSolve(undefined);
		setSelectedLecture(value);
	};
	const toggleSelectQuestionSolve = value => {
		setSelectedChapter(undefined);
		setSelectedLecture(undefined);
		setSelectedQuestionSolve(value);
	};
	const toggleSelectSubject = value => {
		setSelectedSubject(value);
	};

	useEffect(() => {
		async function fetchData() {
			if (selectedLecture || selectedChapter || selectedQuestionSolve) {
				const data = {
					type: selectedChapter
						? 'chapter'
						: selectedLecture
						? 'lecture'
						: 'question-solve',
					typeId: selectedChapter || selectedLecture || selectedQuestionSolve
				};
				await dispatch(getChapterLectureById(data));
			}
		}
		fetchData();
	}, [selectedChapter, selectedLecture, dispatch, selectedQuestionSolve]);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getSubjectByCourse(courseId));
		}
		fetchData();
	}, [courseId, dispatch]);

	const handleReorderContents = async contents => {
		await dispatch(
			reorderContent({
				data: {
					videoContents: contentName === 'video' ? [...contents] : undefined,
					fileContents: contentName === 'file' ? [...contents] : undefined
				},
				typeId: selectedChapter || selectedLecture || selectedQuestionSolve,
				type: selectedChapter
					? 'chapter'
					: selectedLecture
					? 'lecture'
					: 'question-solve'
			})
		);
	};

	return (
		<React.Fragment>
			<AddVideoModal
				isVisible={isAddVideoModalVisible}
				toggleModal={toogleAddVideoModal.bind(this)}
				chapters={selectedChapter}
				courses={course._id}
				lectures={selectedLecture}
				questionSolves={selectedQuestionSolve}
				subjects={selectedSubject}
				contentName={contentName}
				
			/>
			<UploadVideoModal
				isVisible={isUploadVideoModalVisible}
				toggleModal={toogleUploadVideoModal.bind(this)}
				chapters={selectedChapter}
				courses={course._id}
				lectures={selectedLecture}
				questionSolves={selectedQuestionSolve}
				subjects={selectedSubject}
				contentName={contentName}
			/>
			

			<SubHeader
				headText={course.name || 'Title of the course'}
				// subTitle='Version Bangla'
				backIcon={true}
				backTo='/video-lectures'
			/>
			<Content>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between'
					}}
				>
					<p>
						Add, view and edit{' '}
						{contentName === 'file' ? 'lecture notes' : 'video lectures'} for
						specific subject
					</p>
				</div>

				<FilterForAdd
					subjectList={subjectList}
					selectedChapter={selectedChapter}
					selectedLecture={selectedLecture}
					selectedSubject={selectedSubject}
					selectedQuestionSolve={selectedQuestionSolve}
					toggleSelectSubject={toggleSelectSubject.bind(this)}
					toggleSelectChapter={toggleSelectChapter.bind(this)}
					toggleSelectLecture={toggleSelectLecture.bind(this)}
					toggleSelectQuestionSolve={toggleSelectQuestionSolve.bind(this)}
					toogleAddVideoModal={toogleAddVideoModal.bind(this)}
					toogleUploadVideoModal={toogleUploadVideoModal.bind(this)}
					contentName={contentName}
				/>
				<Divider style={{ margin: '0px' }} />
				<VideoListTable
					data={videoList}
					contentName={contentName}
					selectedChapter={selectedChapter}
					selectedLecture={selectedLecture}
					selectedQuestionSolve={selectedQuestionSolve}
					selectedSubject={selectedSubject}
					handleReorderContents={handleReorderContents.bind(this)}
				/>
			</Content>
		</React.Fragment>
	);
};

export default CourseWiseVideoList;
