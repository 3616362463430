import React, { useEffect, useState } from 'react';
import {
	Button,
	Card,
	Input,
	Select,
	Radio,
	Checkbox,
	Row,
	Col,
	Upload,
	Modal,
	Tooltip,
	Space,
	Typography,
	Image,
	Form,
	Table
} from 'antd';
import {
	FileAddOutlined,
	FileImageOutlined,
	LinkOutlined,
	DeleteFilled,
	DeleteOutlined,
	QuestionOutlined,
	ExclamationCircleOutlined,
	EditFilled, 
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';

import {
	addQuestionTitle,
	addOption,
	addQuestionType,
	addAnswer,
	deleteSingleOption,
	deleteQuestion,
	addLink,
	signedUrl as signedUrlRequest,
	patchFileRequest,
	addImage,
	addFile,
	addQuestionNote,
	addPoint,
	addCourseSubjectChapterToQuestion,
	editSingleOption,
	addExplanationExt,
	addOptionType,
	updateImage,
	updateExpFiles,
	addExpTables,
} from '../../stateManager/reducers/questionSlice';
import {
	addPointToQuestion,
	deleteQuestionForExam,
	updateQuestionRequest
} from '../../stateManager/reducers/examSlice';
import './question.css';
import {
	getChapterBySubject,
	getLectureBySubject,
	getQuestionSolveBySubject
} from '../../stateManager/reducers/courseSlice';
import MathInput from '../Common/MathJax';
import { shouldUseKatex } from '../../constants/constFunction';
import { bucket_url } from '../../constants/constString';
import PdfPlaceholder from '../../assets/images/pdf_placeholder.png';
import ExplanationTables from './ExplanationTable';

const { Option } = Select;
const { Text } = Typography;

const questionTypes = [
	{
		name: 'MCQ',
		type: 'MCQ'
	},
	{
		name: 'T/F Questions',
		type: 'MCA'
	},
	{
		name: 'Checkbox',
		type: 'checkbox'
	},
	{
		name: 'Short Question',
		type: 'shortAns'
	},
	{
		name: 'Paragraph',
		type: 'paragraph'
	}
];

const QuestionCard = props => {
	const {
		questionIndex,
		questionObject,
		isExam,
		isEdit,
		courseId,
		subjectList,
		isCreateForExam
	} = props;
	const dispatch = useDispatch();
	const status = useSelector(state => state.questions.status);
	const [options, setOptions] = useState([]);
	const [optionTypes, setOptionTypes] = useState({});
	const [optionToRender, setOptionToRender] = useState('');
	const [answer, setAnswer] = useState([]);
	const [title, setTitle] = useState('');
	const [explanation, setExplanation] = useState('');
	const [showInput, setShowInput] = useState(false);
	const [showNoteInput, setShowNoteInput] = useState(false);
	const [isOptionEditable, setOptionEditable] = useState(false);
	const [editableOption, setEditableOption] = useState({});
	const [selectedQuestionType, setQuestionType] = useState('MCQ');
	const [showAddButton, setShowAddButton] = useState(true);
	const [isModalVisible, setModalVisible] = useState(false);
	const [link, setLink] = useState('');
	const [listOfLink, setListOfLink] = useState([]);
	const [images, setImages] = useState([]);
	const [expFiles,setExpFiles] = useState([]);
	const [isRowColModalVisible,setIsRowColModalVisible] = useState(false)
	const [isTableModalVisible, setIsTableModalVisible] = useState(false);
	const [rows, setRows] = useState(0);
  	const [columns, setColumns] = useState(0);
	const [tableData, setTableData] = useState([]);
	const [data, setData] = useState([]);

	const isAddOptionButtonShown =
		selectedQuestionType === 'MCQ' || selectedQuestionType === 'checkbox' || selectedQuestionType === 'MCA';
	const chapterList = useSelector(state => state.courses.chapterList);
	const lectureList = useSelector(state => state.courses.lectureList);
	const questionSolveList = useSelector(state => state.courses.questionSolveList);

	console.log('Selected Question Type', selectedQuestionType, isEdit);
	useEffect(() => {
		if (questionObject) {
			
			setQuestionType(questionObject?.type);
			setOptions(questionObject?.options || []);
			setOptionTypes(
				questionObject?.optionType &&
					typeof questionObject.optionType === 'string' &&
					Object.keys(questionObject?.optionType).length > 0
					? JSON.parse(questionObject?.optionType)
					: {}
			);
			setAnswer(questionObject?.answer || []);
			setTitle(questionObject?.title || '');
			setExplanation(questionObject?.explanation || '');
			setImages(questionObject?.image || []);
			setExpFiles(questionObject?.explanationExt)
		}
	}, [questionObject]);

	const handleFileUpload = async file => {
		try {
			const res = await dispatch(signedUrlRequest(file.type));
			const { signedUrl, key } = res?.payload?.data;
			const response = await dispatch(patchFileRequest({ signedUrl, file }));
			if (response?.payload?.status === 200) {
				if (file.type.includes('image')) {
					dispatch(addImage({ index: questionIndex, image: key }));
					return;
				} else {
					dispatch(addFile({ index: questionIndex, file: key }));
					return;
				}
			}
		} catch (error) {
			console.log(error.response);
		}
	};

	const handleAddImageToOption = async file => {
		try {
			const res = await dispatch(signedUrlRequest(file.type));
			const { signedUrl, key } = res?.payload?.data;
			const response = await dispatch(patchFileRequest({ signedUrl, file }));
			if (response?.payload?.status === 200) {

				console.log('key', key);
				let t = [...options];
				t.push(file.name);
				setOptions(t);
				const optyp = { ...optionTypes };
				optyp[file.name] = 'image';
				// setOptionTypes({ ...optionTypes, [key]: 'image' });
				setOptionTypes(optyp);
				console.log('22 options', optionTypes);
				setOptionToRender('');
				dispatch(addOption({ index: questionIndex, option: key }));
				let temp = optionTypes;
				temp[key] = 'image';
				dispatch(
					addOptionType({
						index: questionIndex,
						option: key,
						type: 'image'
					})
				);
				return;
			}
		} catch (error) {
			console.log(error.response);
		}
	};

	const handleAddFilesToExplanation = async file => {
		try {
			const res = await dispatch(signedUrlRequest(file.type));
			const { signedUrl, key } = res?.payload?.data;
			const response = await dispatch(patchFileRequest({ signedUrl, file }));
			if (response?.payload?.status === 200) {
				dispatch(addExplanationExt({ index: questionIndex, file: key }));
				return;
			}
		} catch (error) {
			console.log(error.response);
		}
	};

	const handleEditQuestion = async () => {
		console.log('update question handleEditQuestion');
		if (!title) {
			toastr.error('Title cannot be empty!');
			return;
		}
		if (questionObject.type === 'MCQ' || questionObject.type === 'checkobx') {
			if (options.length < 2) {
				toastr.error('Please add at least two valid options');
				return;
			}
			if (answer.length < 1) {
				toastr.error('Please select an answer!');
				return;
			}
		}

		console.log('options', optionTypes);
		const data = {
			title: title,
			options: options,
			answer: answer,
			explanation: explanation || undefined,
			optionType: !!optionTypes ? optionTypes : undefined,

		};

		console.log('data', data);
		const res = await dispatch(
			updateQuestionRequest({ data, questionId: questionObject._id })
		);
		props.toggleModal();
	};

	const handleSubjectChange = async value => {
		
		await dispatch(getLectureBySubject({ subjectId: value }));
		await dispatch(getChapterBySubject({ subjectId: value }));
		await dispatch(getQuestionSolveBySubject({ subjectId: value }));
		dispatch(
			addCourseSubjectChapterToQuestion({
				index: questionIndex,
				key: 'subjectId',
				value
			})
		);
		dispatch(
			addCourseSubjectChapterToQuestion({
				index: questionIndex,
				key: 'courseId',
				value: courseId
			})
		);
	};

	const handleRowColSubmit = () => {
		
		setTableData(
		  Array.from({ length: rows }, (_, rowIndex) =>
			Object.fromEntries(
			  Array.from({ length: columns }, (_, colIndex) => [
				`col${colIndex}`,
				``,
			  ])
			)
		  )
		);
		setIsRowColModalVisible(false);
		setIsTableModalVisible(true);

		console.log("tbl dt",Array.from({ length: rows }, (_, rowIndex) =>
			Object.fromEntries(
			  Array.from({ length: columns }, (_, colIndex) => [
				`col${colIndex}`,
				``,
			  ])
			)
		  ))
	}

	const renderColumns = () => {
		return Array.from({ length: columns }, (_, colIndex) => ({
			//title: `Column ${colIndex + 1}`,
			dataIndex: `col${colIndex}`,
			key: `col${colIndex}`,
			render: (_, record, rowIndex) => (
			<Form.Item
				name={`${rowIndex}-${colIndex}`}
				style={{ margin: 0 }}
				initialValue={record[`col${colIndex}`]}
			>
				<Input placeholder={`Row ${rowIndex + 1} Col ${colIndex + 1}`} />
			</Form.Item>
			),
		}));
	};

	const handleTableSubmit = () => {
		const updatedData = tableData.map((row, rowIndex) => {
		  const rowData = {};
		  for (let col = 0; col < columns; col++) {
			rowData[`col${col}`] = form.getFieldValue(`${rowIndex}-${col}`) || "";
		  }
		  return rowData;
		});
		setData(updatedData);
		console.log("Submitted Data:", updatedData);
		setIsTableModalVisible(false);

		dispatch(addExpTables({ index : questionIndex, explanationTables : updatedData}))
		setRows(0);
		setColumns(0);
		form.resetFields();
	  
	  
	};

	const [form] = Form.useForm();


	return (
		<div className='main'>
			<Modal
				visible={isModalVisible}
				destroyOnClose
				onOk={() => {
					dispatch(addLink({ index: questionIndex, link: link }));
					setModalVisible(!isModalVisible);
					const temp = [...listOfLink];
					setListOfLink([...temp, link]);
				}}
				title={<strong>Add Link</strong>}
			>
				<Input
					type='url'
					placeholder='Enter link'
					onChange={e => {
						setLink(e.target.value);
					}}
					prefix={<LinkOutlined />}
				/>
			</Modal>

			<Card
				style={{ width: 600 }}
				title={
					<React.Fragment>
						<Input.TextArea
							value={isEdit ? title : questionObject.title}
							onChange={e =>
								isEdit
									? setTitle(e.target.value)
									: dispatch(
											addQuestionTitle({
												title: e.target.value,
												index: questionIndex
											})
									  )
							}
							placeholder='Type quesion'
						/>
						{!isEdit && (
							<Text style={{ whiteSpace: 'break-spaces' }}>
								{questionObject.title &&
								shouldUseKatex(questionObject?.title) >= 0 ? (
									<MathInput mathItem={questionObject.title} showBlock={true} />
								) : (
									' ' + questionObject.title
								)}
							</Text>
						)}
						{isEdit && (
							<Text style={{ whiteSpace: 'break-spaces' }}>
								{title && shouldUseKatex(title) >= 0 ? (
									<MathInput mathItem={title} showBlock={true} />
								) : (
									' ' + title
								)}
							</Text>
						)}
					</React.Fragment>
				}
			>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					{isExam && (
						<div>
							<Input
								type='number'
								style={{ maxWidth: 80 }}
								placeholder='Points'
								onChange={e => {
									if (isExam && isCreateForExam) {
										dispatch(
											addPoint({ index: questionIndex, point: e.target.value })
										);
									} else if (isExam) {
										dispatch(
											addPointToQuestion({
												index: questionIndex,
												point: e.target.value
											})
										);
									}
									return;
								}}
							/>
						</div>
					)}
					<Select
						style={{ minWidth: 150 }}
						onChange={value => {
							setQuestionType(value);
							dispatch(addQuestionType({ type: value, index: questionIndex }));
							dispatch(addAnswer({ index: questionIndex, answer: [] }));
						}}
						disabled={isEdit}
						value={questionObject.type}
						placeholder='Select type'
					>
						{questionTypes.map((item, index) => (
							<Option value={item.type} key={index}>
								{item.name}
							</Option>
						))}
					</Select>
				</div>
				{(questionObject?.image || questionObject?.file) &&
									(questionObject?.image?.length > 0 || questionObject?.file?.length > 0) && (
										<Row style={{ marginTop: 20, marginBottom: 20 }}>
											<Col xs={23} md={23} offset={1}>
												<Space>
													
													{!!images &&
														images.map((item, index) => (
															<div>
																<Image
																placeholder
																src={bucket_url + item}
																width={'100px'}
																style={{ objectFit: 'contain' }}
															/>
																<Button onClick={()=>{
																	Modal.confirm({
																		title: 'Do you want to delete this image?',
																		icon: <ExclamationCircleOutlined />,
																		onOk() {

																	let temp = [...images];
																	temp.splice(index, 1);
																	setImages(temp);
																	dispatch(
																		updateImage({
																			index: questionIndex,
																			image: temp
																		})
																	);
																		},
																		onCancel() {
																			console.log('Cancel');
																		},
																	});
																}}>
																<DeleteFilled />
																</Button>
															</div>
															
														))}
													
												</Space>
											</Col>
										</Row>
									)}
				<Row gutter={5} style={{ marginTop: 10 }}>
					<Col xs={24} md={8} style={{ paddingTop: 2.5 }}>
						<Upload
							name='imageUploader'
							accept='image/*'
							onChange={e => {
								if (e.file.status === 'done' || e.file.status === 'error') {
									handleFileUpload(e.file.originFileObj);
								}
							}}
							className='add-que-upload-wrap no-tooltip'
						>
							<Tooltip title='Upload image'>
								<Button
									loading={status === 'loading'}
									style={{ marginRight: 5, display: 'block', width: '100%' }}
									icon={<FileImageOutlined size='large' />}
								>
									{' '}
									Add Image{' '}
								</Button>
							</Tooltip>
						</Upload>
					</Col>
					<Col xs={24} md={8} style={{ paddingTop: 2.5 }}>
						<Upload
							name='fileUploader'
							accept='.pdf'
							onChange={e => {
								if (e.file.status === 'done' || e.file.status === 'error') {
									handleFileUpload(e.file.originFileObj);
								}
							}}
							className='add-que-upload-wrap no-tooltip'
						>
							<Tooltip title='Upload pdf document'>
								<Button
									style={{ marginRight: 5, display: 'block', width: '100%' }}
									icon={<FileAddOutlined size='large' />}
								>
									{' '}
									Add Pdf{' '}
								</Button>
							</Tooltip>
						</Upload>
					</Col>
					<Col xs={24} md={8} style={{ paddingTop: 2.5 }}>
						<Tooltip title='Insert Link'>
							<Button
								onClick={() => setModalVisible(true)}
								style={{ marginRight: 5, display: 'block', width: '100%' }}
								icon={<LinkOutlined size='large' />}
							>
								{' '}
								Add Link{' '}
							</Button>
						</Tooltip>
						<ul className='add-link-list'>
							{listOfLink.map((item, index) => (
								<li>
									<a href={item} title={item} target='_blank'>
										{item}
									</a>{' '}
									<DeleteOutlined />
								</li>
							))}
						</ul>
					</Col>
				</Row>
				{selectedQuestionType === 'MCQ' && (
					<Radio.Group
						style={{ width: '100%' }}
						value={
							isEdit && answer && answer.length > 0
								? answer[0]
								: questionObject.answer && questionObject.answer.length > 0
								? questionObject.answer[0]
								: undefined
						}
						onChange={e => {
							setAnswer([e.target.value]);
							!isEdit &&
								dispatch(
									addAnswer({ answer: [e.target.value], index: questionIndex })
								);
						}}
					>
						{(isEdit &&
							options &&
							options.length > 0 &&
							options.map((item, index) => (
								<div className='option-container'>
									<Radio value={item} key={index}>
										{isOptionEditable && editableOption?.index === index ? (
											<Input
												value={editableOption?.option}
												onChange={e => {
													const temp = { ...editableOption };
													temp.option = e.target.value;
													setEditableOption(temp);
												}}
												onPressEnter={e => {
													const data = e.target.value;
													let temp = [...options];
													temp[index] = data;
													setOptions(temp);
													setEditableOption({});
													setOptionEditable(!isOptionEditable);
												}}
											/>
										) : (
											<Text className='input-options'>
												{item && shouldUseKatex(item) >= 0 ? (
													<MathInput mathItem={item} showBlock={true} />
												) : (
													item
												)}
											</Text>
										)}
									</Radio>
									<div>
										<Space>
											<Button
												onClick={() => {
													setEditableOption({ index: index, option: item });
													setOptionEditable(true);
												}}
											>
												<EditFilled />
											</Button>
											<Button
												onClick={() => {
													let t = [...options];
													t.splice(index, 1);
													setOptions(t);
													!isEdit &&
														dispatch(
															deleteSingleOption({
																questionIndex: questionIndex,
																optionIndex: index
															})
														);
												}}
											>
												<DeleteFilled />
											</Button>
										</Space>
									</div>
								</div>
							))) ||
							(!!questionObject?.options &&
								questionObject.options.map((item, index) => (
									<div className='option-container'>
										<Radio value={item} key={index}>
											{isOptionEditable && editableOption?.index === index ? (
												<Input
													value={editableOption?.option}
													onChange={e => {
														const temp = { ...editableOption };
														temp.option = e.target.value;
														setEditableOption(temp);
													}}
													onPressEnter={e => {
														const data = e.target.value;
														dispatch(
															editSingleOption({
																questionIndex,
																optionIndex: index,
																data
															})
														);
														setEditableOption({});
														setOptionEditable(!isOptionEditable);
													}}
												/>
											) : (
												<Text className='input-options'>
													{item && shouldUseKatex(item) >= 0 ? (
														<MathInput mathItem={item} showBlock={true} />
													) : (
														item
													)}
												</Text>
											)}
										</Radio>
										<div>
											<Space>
												{optionTypes[item] !== 'image' && (
													<Button
														onClick={() => {
															setEditableOption({ index: index, option: item });
															setOptionEditable(true);
														}}
													>
														<EditFilled />
													</Button>
												)}
												<Button
													onClick={() => {
														let t = [...options];
														t.splice(index, 1);
														setOptions(t);
														!isEdit &&
															dispatch(
																deleteSingleOption({
																	questionIndex: questionIndex,
																	optionIndex: index
																})
															);
													}}
												>
													<DeleteFilled />
												</Button>
											</Space>
										</div>
									</div>
								)))}
					</Radio.Group>
				)}
				{selectedQuestionType === 'MCA' && (
					<>
						{(isEdit &&
							options &&
							options.length > 0 &&
							options.map((item, index) => (
								<div className='option-container'>
									<Radio value={item} key={index} disabled={true}>
										{isOptionEditable && editableOption?.index === index ? (
											<Input
												value={editableOption?.option}
												onChange={e => {
													const temp = { ...editableOption };
													temp.option = e.target.value;
													setEditableOption(temp);
												}}
												onPressEnter={e => {
													const data = e.target.value;
													let temp = [...options];
													temp[index] = data;
													setOptions(temp);
													setEditableOption({});
													setOptionEditable(!isOptionEditable);
												}}
											/>
										) : (
											<Text className='input-options'>
												{item && shouldUseKatex(item) >= 0 ? (
													<MathInput mathItem={item} showBlock={true} />
												) : (
													item
												)} 
											</Text>
										)}
									</Radio>
									<div>
										<Space>
										
										<Radio.Group
												style={{ width: '100%' }}
												value={
													answer && answer.length > 0
														? answer[index]
														: questionObject.answer && questionObject.answer.length > 0
														? questionObject.answer[index]
														: undefined
												}
												onChange={e => {
													setAnswer( (prevArr) => {
														const newArr = [...prevArr]
														newArr[index] = e.target.value
														return newArr
													});
													
														dispatch(
															addAnswer({ answer: e.target.value, index: questionIndex , answerIndex : index })
														);
												}}
											>
												<Radio value={'true'}>True</Radio>
												<Radio value={'false'}>False</Radio>
											</Radio.Group>
											{/* <Button
												onClick={() => {
													setEditableOption({ index: index, option: item });
													setOptionEditable(true);
												}}
											>
												<EditFilled />
											</Button> */}
											<Button
												onClick={() => {
													let t = [...options];
													t.splice(index, 1);
													setOptions(t);
													!isEdit &&
														dispatch(
															deleteSingleOption({
																questionIndex: questionIndex,
																optionIndex: index
															})
														);
												}}
											>
												<DeleteFilled />
											</Button>
										</Space>
									</div>
								</div>
							))) ||
							(!!questionObject?.options &&
								questionObject.options.map((item, index) => (
									<div className='option-container'>
										<Radio value={item} key={index} disabled={true}>
											{isOptionEditable && editableOption?.index === index ? (
												<Input
													value={editableOption?.option}
													onChange={e => {
														const temp = { ...editableOption };
														temp.option = e.target.value;
														setEditableOption(temp);
													}}
													onPressEnter={e => {
														const data = e.target.value;
														dispatch(
															editSingleOption({
																questionIndex,
																optionIndex: index,
																data
															})
														);
														setEditableOption({});
														setOptionEditable(!isOptionEditable);
													}}
												/>
											) : (
												<Text className='input-options'>
													{item && shouldUseKatex(item) >= 0 ? (
														<MathInput mathItem={item} showBlock={true} />
													) : (
														item
													)}
												</Text>
											)} 
										</Radio>
										<div>
											<Space>
											<Radio.Group
												style={{ width: '100%' }}
												value={
													answer && answer.length > 0
														? answer[index]
														: questionObject.answer && questionObject.answer.length > 0
														? questionObject.answer[index]
														: undefined
												}
												onChange={e => {
													setAnswer( (prevArr) => {
														const newArr = [...prevArr]
														newArr[index] = e.target.value
														return newArr
													});
													
														dispatch(
															addAnswer({ answer: e.target.value, index: questionIndex , answerIndex : index })
														);
												}}
											>
												<Radio value={'true'}>True</Radio>
												<Radio value={'false'}>False</Radio>
											</Radio.Group>
												{optionTypes[item] !== 'image' && (
													<Button
														onClick={() => {
															setEditableOption({ index: index, option: item });
															setOptionEditable(true);
														}}
													>
														<EditFilled />
													</Button>
												)}
												<Button
													onClick={() => {
														let t = [...options];
														t.splice(index, 1);
														setOptions(t);
														!isEdit &&
															dispatch(
																deleteSingleOption({
																	questionIndex: questionIndex,
																	optionIndex: index
																})
															);
													}}
												>
													<DeleteFilled />
												</Button>
											</Space>
										</div>
									</div>
								)))}
					</>
				)}
				{selectedQuestionType === 'checkbox' && (
					<Checkbox.Group
						value={
							isEdit && answer && answer.length > 0
								? answer
								: questionObject.answer
						}
						style={{ width: '100%', paddingTop: 10 }}
						onChange={value => {
							setAnswer(value);
							!isEdit &&
								dispatch(addAnswer({ answer: value, index: questionIndex }));
						}}
					>
						{(isEdit &&
							options &&
							options.length > 0 &&
							options.map((item, index) => (
								<div
									style={{ display: 'flex', justifyContent: 'space-between' }}
									className='option-container'
								>
									<Checkbox value={item} key={index}>
										{isOptionEditable && editableOption?.index === index ? (
											<Input
												value={editableOption?.option}
												onChange={e => {
													const temp = { ...editableOption };
													temp.option = e.target.value;
													setEditableOption(temp);
												}}
												onPressEnter={e => {
													const data = e.target.value;
													let temp = [...options];
													temp[index] = data;
													setOptions(temp);
													setEditableOption({});
													setOptionEditable(!isOptionEditable);
												}}
											/>
										) : (
											<Text className='input-options'>
												{item && shouldUseKatex(item) >= 0 ? (
													<MathInput mathItem={item} showBlock={true} />
												) : (
													item
												)}
											</Text>
										)}
									</Checkbox>
									<div>
										<Space>
											<Button
												onClick={() => {
													setEditableOption({ index: index, option: item });
													setOptionEditable(true);
												}}
											>
												<EditFilled />
											</Button>
											<Button
												onClick={() => {
													let t = [...options];
													t.splice(index, 1);
													setOptions(t);
													!isEdit &&
														dispatch(
															deleteSingleOption({
																questionIndex: questionIndex,
																optionIndex: index
															})
														);
												}}
											>
												<DeleteFilled />
											</Button>
										</Space>
									</div>
								</div>
							))) ||
							(!!questionObject?.options &&
								questionObject.options.map((item, index) => (
									<div
										style={{ display: 'flex', justifyContent: 'space-between' }}
										className='option-container'
									>
										<Checkbox
											value={item}
											key={index}
											style={{ display: 'flex', alignItems: 'center' }}
										>
											{isOptionEditable && editableOption?.index === index ? (
												<Input
													value={editableOption?.option}
													onChange={e => {
														const temp = { ...editableOption };
														temp.option = e.target.value;
														setEditableOption(temp);
													}}
													onPressEnter={e => {
														const data = e.target.value;
														dispatch(
															editSingleOption({
																questionIndex,
																optionIndex: index,
																data
															})
														);
														setEditableOption({});
														setOptionEditable(!isOptionEditable);
													}}
												/>
											) : (
												<Text className='input-options'>
													{item && shouldUseKatex(item) >= 0 ? (
														<MathInput mathItem={item} showBlock={true} />
													) : (
														item
													)}
												</Text>
											)}
										</Checkbox>
										<div>
											<Space>
												<Button
													onClick={() => {
														setEditableOption({ index: index, option: item });
														setOptionEditable(true);
													}}
												>
													<EditFilled />
												</Button>
												<Button
													onClick={() => {
														let t = [...options];
														t.splice(index, 1);
														setOptions(t);
														!isEdit &&
															dispatch(
																deleteSingleOption({
																	questionIndex: questionIndex,
																	optionIndex: index
																})
															);
													}}
												>
													<DeleteFilled />
												</Button>
											</Space>
										</div>
									</div>
								)))}
					</Checkbox.Group>
				)}
				{isOptionEditable && (
					<MathInput mathItem={editableOption?.option} showBlock={true} />
				)}
				{showInput && (
					<React.Fragment>
						<Input
							style={{ marginTop: 10, marginBottom: 20 }}
							placeholder='New Option'
							value={optionToRender}
							onChange={e => setOptionToRender(e.target.value)}
							onPressEnter={e => {
								setShowInput(options.length > 2 ? false : true);
								setShowAddButton(true);
								let t = [...options];
								t.push(e.target.value);
								setOptions(t);
								setOptionToRender('');
								!isEdit &&
									dispatch(
										addOption({ index: questionIndex, option: e.target.value })
									);
								!isEdit &&
									dispatch(
										addOptionType({
											index: questionIndex,
											option: e.target.value,
											type: 'text'
										})
									);
							}}
						/>
						{showInput && (
							<MathInput mathItem={optionToRender} showBlock={true} />
						)}
					</React.Fragment>
				)}
				{showNoteInput && (
					<React.Fragment>
						<Input.TextArea
							style={{ marginTop: 10, marginBottom: 10 }}
							placeholder='Explanation'
							value={isEdit ? explanation : questionObject.explanation}
							onChange={e => {
								!isEdit
									? dispatch(
											addQuestionNote({
												index: questionIndex,
												explanation: e.target.value
											})
									  )
									: setExplanation(e.target.value);
							}}
						/>
						{!isEdit && (
							<Text style={{ whiteSpace: 'pre-wrap' }}>
								<MathInput
									mathItem={questionObject?.explanation}
									showBlock={true}
								/>
							</Text>
						)}
						{isEdit && (
							<Text style={{ whiteSpace: 'pre-wrap' }}>
								<MathInput mathItem={explanation} showBlock={true} />
							</Text>
						)}
					</React.Fragment>
				)}
				{showAddButton && (
					<React.Fragment>
						<Row style={{ marginTop: 10 }}>
							<Col>
								{(selectedQuestionType === 'MCQ' ||
									selectedQuestionType === 'MCA' ||
									selectedQuestionType === 'checkbox') && (
									<React.Fragment>
										<Button
											style={{ marginRight: 10 }}
											onClick={() => {
												setShowInput(true);
												setShowAddButton(false);
											}}
										>
											Add New Option
										</Button>
										<Upload
											name='imageUploader'
											accept='image/*'
											className='no-tooltip'
											onChange={e => {
												if (
													e.file.status === 'done' ||
													e.file.status === 'error'
												) {
													handleAddImageToOption(e.file.originFileObj);
												}
											}}
										>
											<Tooltip title='Upload image'>
												<Button
													loading={status === 'loading'}
													icon={<FileImageOutlined size='large' />}
												>
													{' '}
													Add Image Option{' '}
												</Button>
											</Tooltip>
										</Upload>
									</React.Fragment>
								)}
							</Col>
						</Row>
						<Row style={{ marginTop: 10 }}>
							<Col>
								<Button
									style={{
										marginLeft: isAddOptionButtonShown ? 0 : 0,
										marginRight: 10
									}}
									onClick={() => {
										setShowNoteInput(true);
									}}
									icon={<QuestionOutlined />}
								>
									Add Explanation
								</Button>
							</Col>
							<Col>
								<Upload
									name='imageUploader'
									accept='image/*,.pdf'
									// showUploadList={false}
									onChange={e => {
										if (e.file.status === 'done' || e.file.status === 'error') {
											handleAddFilesToExplanation(e.file.originFileObj);
										}
									}}
									className='no-tooltip'
								>
									<Tooltip title='Upload image'>
										<Button
											loading={status === 'loading'}
											icon={<FileImageOutlined size='large' />}
										>
											{' '}
											Add file to explanation{' '}
										</Button>
									</Tooltip>
								</Upload>
							</Col>
							<Col>
							<Button
								style={{ marginRight: 10 }}
								onClick={() => {
									setIsRowColModalVisible(true)
								}}
							>
								Add Table to Explanation
							</Button>
							</Col>
						</Row>
					</React.Fragment>
				)}

				{/* {section for explaination files render} */}

				{
				expFiles?.length > 0 
				&&
					<>
						<div style={{marginTop:'10px'}}>
							<h3>Explanation Files:</h3>
						</div>
						<div className='' style={{marginBottom:'36px'}}>

						{
							expFiles?.map((explanationFiles,key) => {
								return (
									<div style={{marginTop:'5px'}}>
										<Row>
										<Col xs={12} md={12}>
												<Image
													preview={false}
													placeholder
													src={bucket_url+explanationFiles}
													width={'20%'}
													style={{ objectFit: 'contain' }}
													fallback={PdfPlaceholder}
												/>
										</Col>
										<Col xs={12} md={12}>
											<Button
												onClick={() => {
													dispatch(
														updateExpFiles(
															{
																index : questionIndex,
																explanationFileIndex : key
															}
														)
													)
												}}
												style={{
													float: 'right'
												}}
											>
												<DeleteFilled />
											</Button>
										</Col>
									</Row>
									</div>
								)
							})
						}	
						
							
							
						</div>
					</>
				}
				
				{/* {end section} */}

				{isEdit && (
					<Row style={{ marginTop: 10 }}>
						<Button
							type='primary'
							onClick={() => {
								handleEditQuestion();
							}}
						>
							Update Question
						</Button>
					</Row>
				)}

				{isExam && isCreateForExam && (
					<Row style={{ marginTop: 10 }}>
						<Col>
							<Space>
								<Select
									onChange={value => {
										handleSubjectChange(value);
									}}
									placeholder='Subject'
								>
									{!!subjectList &&
										subjectList.map((item, index) => (
											<Option value={item._id} key={index}>
												{item.name}
											</Option>
										))}
								</Select>
								<Select
									onChange={value =>
										dispatch(
											addCourseSubjectChapterToQuestion({
												index: questionIndex,
												key: 'chapterId',
												value
											})
										)
									}
									placeholder='Chapter'
								>
									{chapterList &&
										chapterList.map((item, index) => (
											<Option value={item._id} key={index}>
												{item.name}
											</Option>
										))}
								</Select>
								<Select
									onChange={value =>
										dispatch(
											addCourseSubjectChapterToQuestion({
												index: questionIndex,
												key: 'lectureId',
												value
											})
										)
									}
									placeholder='Lecture'
								>
									{!!lectureList &&
										lectureList.map((item, index) => (
											<Option value={item._id} key={index}>
												{item.name}
											</Option>
										))}
								</Select>
								<Select
									onChange={value =>
										dispatch(
											addCourseSubjectChapterToQuestion({
												index: questionIndex,
												key: 'questionSolveId',
												value
											})
										)
									}
									placeholder='Question Solve'
								>
									{!!questionSolveList &&
										questionSolveList.map((item, index) => (
											<Option value={item._id} key={index}>
												{item.name}
											</Option>
										))}
								</Select>
							</Space>
						</Col>
					</Row>
				)}

				{!isEdit && (
					<Button
						onClick={() => {
							if (isExam && !isCreateForExam) {
								dispatch(deleteQuestionForExam({ index: questionIndex }));
								return;
							}
							dispatch(deleteQuestion({ index: questionIndex }));
						}}
						type='primary'
						style={{ position: 'absolute', right: 1, bottom: 5 }}
					>
						<DeleteFilled />
					</Button>
				)}
				
				{
					questionObject?.explanationTables && (
						<ExplanationTables 
						questionIndex={questionIndex}
						isEditable={true}
						initialTables={questionObject.explanationTables} />
					)
				}
				

				<Modal
					title="Enter Row and Column"
					open={isRowColModalVisible}
					destroyOnClose
					onOk={handleRowColSubmit}
					onCancel={() => {
						setRows(0);
						setColumns(0);
						setIsRowColModalVisible(false);
					}}
				>
					<Input
						placeholder="Enter number of rows"
						type="number"
						value={rows}
						onChange={(e) => setRows(Number(e.target.value))}
						style={{ marginBottom: "10px" }}
					/>
					<Input
						placeholder="Enter number of columns"
						type="number"
						value={columns}
						onChange={(e) => setColumns(Number(e.target.value))}
					/>
				</Modal>

				<Modal
					title="Enter Table Data"
					visible={isTableModalVisible}
					onOk={handleTableSubmit}
					onCancel={() => setIsTableModalVisible(false)}
					width={800}
				>
					<Form form={form}>
					<Table
						dataSource={tableData}
						columns={renderColumns()}
						pagination={false}
						rowKey={(record, index) => index}
					/>
					</Form>
				</Modal>

			</Card>
		</div>
	);
};

export default QuestionCard;
