import React, { useEffect } from 'react';
import { Row, Col, Select, Button, Space, Input, Upload } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
import { readString } from 'react-papaparse';

import {
	getLectureBySubject,
	getChapterBySubject,
	getQuestionSolveBySubject
} from '../../stateManager/reducers/courseSlice';
import {
	addFilters,
	saveQuestionRequest
} from '../../stateManager/reducers/questionSlice';
import { resetQuestionsForExam } from '../../stateManager/reducers/examSlice';
import { LOCAL_KEY } from '../../constants/constString';
import { getLocalStorageData } from '../../constants/constFunction';
import { useLocation } from 'react-router-dom';
import { question } from '../../stateManager/reducers/mentorSlice';
const { Option } = Select;

const FilterForAdd = props => {
	const { subjectList, courseId } = props;
	const dispatch = useDispatch();
	const chapterList = useSelector(state => state.courses.chapterList);
	const lectureList = useSelector(state => state.courses.lectureList);
	const questionSolveList = useSelector(state => state.courses.questionSolveList);
	const isSegmentedExam = useSelector(state => state.exams.isSegmentedExam);
	const segmentedSubjectIds = useSelector(state => state.exams.segmentedSubjectIds);

	const location = useLocation();
	const questionBank = location.pathname.includes('question-bank');
	console.log('Question Bank', questionBank);


	console.log('QuestionTable', segmentedSubjectIds, isSegmentedExam);
	const status = useSelector(state => state.questions.status);
	
	useEffect(() => {
		localStorage.setItem('selectedSubjectExamView', '');

		// setSelectedSubjectForFilter(filters.selectedSubjectForFilter);
		//dispatch(addFilters({ key: 'selectedSubjectForFilter', value: filters?.selectedSubjectForFilter }));
		
	}, []);



	const selectedSubjectForFilter = useSelector(
		state => state.questions.selectedSubjectForFilter
	);
	const selectedChapterForFilter = useSelector(
		state => state.questions.selectedChapterForFilter
	);
	const selectedLectureForFilter = useSelector(
		state => state.questions.selectedLectureForFilter
	);

	const selectedQuestionSolveForFilter = useSelector(
		state => state.questions.selectedQuestionSolveForFilter
	);

	const handleFileUpload = file => {
		let read = new FileReader();
		read.readAsText(file);

		read.onloadend = async function () {
			const result = await readString(read.result, {
				header: true,
				skipEmptyLines: true,
				transform: (value, header) => {
					if (header === 'answer') {
						return value.split(',').map(item => item.trim());
					}
					if (header === 'notes' || header === 'explanation') {
						return !!value && value.length > 0 ? value : undefined;
					}
					return value;
				}
			});
			console.log('result', result);
			const { data: questionData } = result;
			const questions = questionData.map(item => {
				let answer = [];
				if (item.type === 'MCQ' || item.type === 'checkbox') {
					// eslint-disable-next-line
					item.answer.map(element => {
						const index = parseInt(element);
						answer.push(item[`option${index}`]);
					});
				}
				if(item.type === 'MCA'){
					// eslint-disable-next-line
					item.answer.map(element => {
						
						answer.push(element);
					});

				}
				if (item.type === 'shortAns' || item.type === 'paragraph') {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					delete obj['options'];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					return {
						...obj,
						answer,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter,
						questionSolveId: selectedQuestionSolveForFilter
					};
				} else {
					const { ...otherProps } = item;
					const obj = { ...otherProps };
					const options = [
						item.option1,
						item.option2,
						item.option3,
						item.option4
					];
					delete obj['option1'];
					delete obj['option2'];
					delete obj['option3'];
					delete obj['option4'];
					if(item.type === 'MCA'){
						options.push(item?.option5);
						delete obj['option5'];						
					}
					return {
						...obj,
						answer,
						options,
						courseId,
						subjectId: selectedSubjectForFilter,
						lectureId: selectedLectureForFilter,
						chapterId: selectedChapterForFilter,
						questionSolveId: selectedQuestionSolveForFilter
					};
				}
			});

			console.log('questions', questions);
			const data = { questions };
			try {
				const res = await dispatch(saveQuestionRequest(data));
			} catch (error) {
				console.log(error);
			}
		};
	};

	const getFilteredSubjectList = () => {
		if (!questionBank && isSegmentedExam) {
			return subjectList.filter(item => segmentedSubjectIds.includes(item._id));
		}
		return subjectList;
	};

	useEffect(() => {
		console.log('selectedSubjectForFilter', selectedSubjectForFilter);
		selectedSubjectForFilter && handleSubjectChange(selectedSubjectForFilter);
	}, [selectedSubjectForFilter]);

	const handleSubjectChange = async subjectId => {
		console.log('subject', subjectId);
		if(!isSegmentedExam){
			dispatch(resetQuestionsForExam());
		}
		const subject = subjectList.find(item => item._id === subjectId);
		localStorage.setItem('selectedSubjectExamView', subject?.name);
		await dispatch(getLectureBySubject({ subjectId }));
		await dispatch(getChapterBySubject({ subjectId }));
		await dispatch(getQuestionSolveBySubject({ subjectId }));

		//dispatch(addFilters({ key: 'selectedLectureForFilter', value: filters?.selectedLectureForFilter }));
		//dispatch(addFilters({ key: 'selectedChapterForFilter', value: filters?.selectedChapterForFilter }));
		//dispatch(addFilters({ key: 'selectedQuestionSolveForFilter', value: filters?.selectedQuestionSolveForFilter }));
		
	};

	const getAddQuestionUrl = () => {
		if (selectedChapterForFilter) {
			return `/add-question/${courseId}/${selectedSubjectForFilter}/${selectedChapterForFilter}/chapter`;
		}
		if (selectedLectureForFilter) {
			return `/add-question/${courseId}/${selectedSubjectForFilter}/${selectedLectureForFilter}/lecture`;
		}
		if (selectedQuestionSolveForFilter) {
			return `/add-question/${courseId}/${selectedSubjectForFilter}/${selectedQuestionSolveForFilter}/questionSolve`;
		}
	}

	return (
		<div style={{ background: '#fff', padding: 0 }}>
			<Row
				gutter={[8, { xs: 8, sm: 2, md: 16, lg: 16 }]}
				style={{ paddingLeft: 15, paddingRight: 15 }}
				align='middle'
				justify='space-between'
			>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Search Question</strong>
					<Input
						onChange={e => {
							setTimeout(() => {
								props.getQuestionByTitle(e.target.value);
							}, 1000);
						}}
						style={{ width: '100%' }}
						placeholder='Search with title'
					/>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Subject</strong>
					<Select
						value={selectedSubjectForFilter}
						onChange={value => {
							handleSubjectChange(value);
							dispatch(
								addFilters({ key: 'selectedSubjectForFilter', value: value })
							);
							dispatch(
								addFilters({
									key: 'selectedLectureForFilter',
									value: undefined
								})
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: undefined
								})
							);

							dispatch(
								addFilters({
									key: 'selectedQuestionSolveForFilter',
									value: undefined
								})
							);

						}}
						style={{ width: '100%' }}
						placeholder='Select Subject'
					>
						{getFilteredSubjectList().map((item, index) => (
							<Option key={item._id} value={item._id}>
								{item.name}
							</Option>
						))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Lecture</strong>
					<Select
						onChange={value => {

							const lecture = lectureList.find(item => item._id === value);
							localStorage.setItem('selectedLectureExamView', lecture.name);
							dispatch(
								addFilters({ key: 'selectedLectureForFilter', value: value })
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: undefined
								})
							);
							dispatch(
								addFilters({
									key: 'selectedQuestionSolveForFilter',
									value: undefined
								})
							);
						}}
						value={selectedLectureForFilter}
						style={{ width: '100%' }}
						placeholder='Select Lecutre'
					>
						{!!lectureList &&
							lectureList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Chapter</strong>
					<Select
						onChange={value => {
							dispatch(
								addFilters({
									key: 'selectedLectureForFilter',
									value: undefined
								})
							);
							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: value
								})
							);
							dispatch(
								addFilters({
									key: 'selectedQuestionSolveForFilter',
									value: undefined
								})
							);
						}}
						style={{ width: '100%' }}
						placeholder='Select Chapter'
						value={selectedChapterForFilter}
					>
						{!!chapterList &&
							chapterList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={5} lg={4}>
					<strong>Select Question Solve</strong>
					<Select
						onChange={value => {
							dispatch(
								addFilters({
									key: 'selectedQuestionSolveForFilter',
									value: value
								})
							);

							dispatch(
								addFilters({
									key: 'selectedLectureForFilter',
									value: undefined
								})
							);

							dispatch(
								addFilters({
									key: 'selectedChapterForFilter',
									value: undefined
								})
							);
						}}
						style={{ width: '100%' }}
						placeholder='Select Question Solve'
						value={selectedQuestionSolveForFilter}
					>
						{!!questionSolveList &&
							questionSolveList.map((item, index) => (
								<Option key={item._id} value={item._id}>
									{item.name}
								</Option>
							))}
					</Select>
				</Col>
				<Col xs={24} sm={24} md={8} lg={5} offset={1}>
					<Space>
						<Upload
							accept={'.csv'}
							showUploadList={false}
							onChange={e => {
								if (e.file.status === 'done' || e.file.status === 'error') {
									handleFileUpload(e.file.originFileObj);
								}
							}}
						>
							<Button
								loading={status === 'loading'}
								disabled={
									!selectedSubjectForFilter ||
									(!selectedChapterForFilter && !selectedLectureForFilter && !selectedQuestionSolveForFilter)
								}
								type='dashed'
								icon={<UploadOutlined />}
							>
								Upload
							</Button>
						</Upload>
						<Link
							to={getAddQuestionUrl()}
						>
							<Button
								disabled={
									!selectedSubjectForFilter ||
									(!selectedChapterForFilter && !selectedLectureForFilter && !selectedQuestionSolveForFilter)
								}
								// onClick={() => props.toogleAddVideoModal()}
								type='primary'
							>
								Add Question
							</Button>
						</Link>
					</Space>
				</Col>
			</Row>
		</div>
	);
};

export default FilterForAdd;
