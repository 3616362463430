import React, { useEffect, useState } from 'react';
import {
	Typography,
	Layout,
	Row,
	Col,
	Card,
	Divider,
	Button,
	Space,
	Modal
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
	PrinterFilled,
	CheckOutlined,
	EditOutlined,
	DeleteOutlined,
	PlusOutlined,
	ExportOutlined
} from '@ant-design/icons';
import toastr from 'toastr';

import MCQComponent from '../../../Questions/AdminView/AdminMCQ.component';
import MCAComponent from '../../../Questions/AdminView/AdminMCA.component';
import ParagraphComponent from '../../../Questions/AdminView/AdminParagraph.component';
import ShortAnsComponent from '../../../Questions/AdminView/AdminShortAns.component';
import CheckBoxComponent from '../../../Questions/AdminView/AdminCheckBox.component';
import QuestionCard from '../../../QuestionBank/QuestionCard';

import {
	getExamById,
	removeQuestionFromExamRequest,
	updateQuestionStatusRequest,
	addQuestionToExamRequest,
	updateExamStatusRequest,
	examExportRequest
} from '../../../../stateManager/reducers/examSlice';
import {
	resetQuestionToInitial,
	saveQuestionRequest
} from '../../../../stateManager/reducers/questionSlice';

import './ExamDetails.page.style.css';
import { isInstructor } from '../../../../constants/constFunction';
import SegmentedExamDetails from './SegmentedExamDetails';
import {getSubjectByCourse} from '../../../../stateManager/reducers/courseSlice';
import { use } from 'react';

const { Content } = Layout;
const { Title } = Typography;

const teacherView = true;

const ExamDetails = props => {
	const { examId, courseId } = props.match.params;
	const dispatch = useDispatch();
	const [isEditModalVisible, setEditModalVisible] = useState(false);
	const [isAddModalVisible, setAddModalVisible] = useState(false);
	const [questionObject, setQuestionObject] = useState({});
	const [isApproved, setIsApproved] = useState(false);
	const examDetailsData = useSelector(state => state.exams.examDetails);
	const questionsList = useSelector(
		state => state.exams?.examDetails?.questions
	);
	const unSavedQuestion = useSelector(state => state.questions.questionList);
	const status = useSelector(state => state.exams.status);

	console.log('examDetailsData', examDetailsData, courseId);

	useEffect(() => {
		async function fetchData() {
			await dispatch(getExamById({ examId, courseId }));
			
		}
		fetchData();
	}, [examId, courseId, dispatch]);

	useEffect(() => {
		const fetchSubjects = async () => {
			await dispatch(getSubjectByCourse(examDetailsData?.courseId));
		}
		if(examDetailsData && examDetailsData?.isSegmentedExam){
			fetchSubjects();
		}
	}, [examDetailsData]);


	const toggleModal = () => {
		setEditModalVisible(!isEditModalVisible);
	};
	// eslint-disable-next-line
	const toggleAddModal = () => {
		setAddModalVisible(!isAddModalVisible);
	};

	const handleQuestionStatus = async questionId => {
		try {
			const res = await dispatch(
				updateQuestionStatusRequest({
					data: { status: 'approved' },
					questionId: questionId
				})
			);
		} catch (error) {
			console.log(error);
		}
	};

	const handleDeleteQuestion = async questionId => {
		const { _id: examId } = examDetailsData;
		try {
			const res = await dispatch(
				removeQuestionFromExamRequest({ questionId, examId })
			);
		} catch (error) {
			console.log(error);
		}
	};

	const validateQuestion = async () => {
		let isValid = true;
		for (let i = 0; i < unSavedQuestion.length; i++) {
			const question = unSavedQuestion[i];
			if (!question.title || question.title.length < 5) {
				toastr.error('Please add a valid title to the question ' + (i + 1));
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.options || question.options.length < 2)
			) {
				toastr.error(
					'Please add at least two valid option for the question ' + (i + 1)
				);
				isValid = false;
				break;
			} else if (
				(question.type === 'MCQ' || question.type === 'checkbox') &&
				(!question.answer || question.answer.length < 1)
			) {
				toastr.error('Please select an answer for the question ' + (i + 1));
				isValid = false;
				break;
			}
		}

		if (isValid) {
			await saveQuestion();
		}
		return;
	};

	const saveQuestion = async () => {
		const questions = unSavedQuestion.map(item => {
			if (item.hasOwnProperty('point')) {
				const obj = { ...item };
				delete obj['point'];
				return {
					...obj,
					courseId: examDetailsData.courseId
				};
			}
			return {
				...item,
				courseId: examDetailsData.courseId
			};
		});
		const data = { questions: questions };

		try {
			const res = await dispatch(saveQuestionRequest(data));

			if (res?.payload?.status === '200') {
				const resData = res.payload.data;
				const dataToAdd = resData.map((item, index) => {
					return {
						question: item?._id,
						point:
							unSavedQuestion[index]?.point || examDetailsData?.globalPoint || 1
					};
				});
				const res1 = await dispatch(
					addQuestionToExamRequest({
						examId: examDetailsData._id,
						data: { questions: dataToAdd },
						questionData: res?.payload?.data
					})
				);
				dispatch(resetQuestionToInitial());
				setAddModalVisible(!isAddModalVisible);
			}
		} catch (error) {
			console.log(error);
		}
		// history.goBack();
	};

	const handleAddQuestion = async () => {
		validateQuestion();
	};

	const handelApproveExam = async () => {
		try {
			const data = { status: 'approved' };
			// eslint-disable-next-line
			const res = await dispatch(
				updateExamStatusRequest({ examId: examDetailsData._id, data })
			);
			setIsApproved(true);
		} catch (error) {
			console.log(error);
		}
	};

	const showApproveExamButton = () => {
		if (examDetailsData && questionsList && questionsList?.length > 0) {
			let show = true;
			for (let i = 0; i < questionsList.length; i++) {
				const question = questionsList[i]?.question;
				if (question.status !== 'approved') {
					show = false;
					break;
				}
			}
			return show;
		}
		return false;
	};

	return (
		<React.Fragment>
			<Modal
				destroyOnClose={true}
				visible={isEditModalVisible}
				footer={null}
				onCancel={() => setEditModalVisible(!isEditModalVisible)}
			>
				<QuestionCard
					questionObject={questionObject?.questionObject}
					questionIndex={questionObject?.questionIndex}
					isEdit={true}
					toggleModal={toggleModal.bind(this)}
				/>
			</Modal>

			<Modal
				destroyOnClose={true}
				visible={isAddModalVisible}
				okText='Add Question'
				okButtonProps={{ loading: status === 'loading' }}
				onOk={() => handleAddQuestion()}
				onCancel={() => setAddModalVisible(!isAddModalVisible)}
			>
				{unSavedQuestion.map((item, index) => (
					<QuestionCard
						isExam={true}
						questionObject={item}
						questionIndex={index}
						isCreateForExam={true}
						toggleModal={toggleModal.bind(this)}
					/>
				))}
			</Modal>

			<Content className='exam-details-page teachers-view'>
				<div
					className='site-layout-background'
					style={{
						padding: 15,
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center'
					}}
				>
					<Title level={3} style={{ marginBottom: 0 }}>
						{examDetailsData?.title || 'Not given'}
					</Title>

					<div>
						{!isInstructor() && (
							<Button
								size='large'
								type='dashed'
								icon={<PlusOutlined />}
								style={{ margin: '10px' }}
								onClick={() => setAddModalVisible(!isAddModalVisible)}
							>
								Add Question
							</Button>
						)}

						{showApproveExamButton() && !isInstructor() && (
							<Button
								size='large'
								type='default'
								style={{ margin: '10px', background: 'green', color: 'white' }}
								icon={<CheckOutlined />}
								onClick={() => handelApproveExam()}
							>
								{examDetailsData?.status === 'approved' || isApproved
									? 'APPROVED'
									: 'Approve Exam'}
							</Button>
						)}

						<Button
							size='large'
							type='default'
							style={{ margin: '10px' }}
							icon={<ExportOutlined />}
							onClick={() =>
								dispatch(examExportRequest({ examId: examDetailsData?._id }))
							}
						>
							Export Exam
						</Button>

						{teacherView && (
							<Button
								size='large'
								type='primary'
								icon={<PrinterFilled />}
								style={{ margin: '10px' }}
								onClick={() => window.print()}
							>
								Print
							</Button>
						)}
					</div>
				</div>

				<Row className='print-teacher' style={{ paddingBottom: 10 }}>
					<Col xs={24} className=''>
						<Title
							className='print-only exam-title-teacher-view'
							level={3}
							style={{ padding: 25, textAlign: 'center' }}
						>
							{examDetailsData?.title || 'Not given'}
						</Title>
						{examDetailsData?.isSegmentedExam && <SegmentedExamDetails examDetails={examDetailsData.segmentedExamDetails} />}
						<Card bordered={false} style={{ background: 'transparent' }}>
							{questionsList &&
								questionsList.map((question, index) => {
									return (
										<div>
											<Row
												className='question-row'
												style={{ paddingBottom: 0 }}
											>
												<Col xs={24} className='questions-wrapper'>
													{question?.question?.type === 'MCQ' && (
														<MCQComponent
															questionIndex={index}
															question={question?.question}
															point={question?.point}
														/>
													)}
													{question?.question?.type === 'MCA' && (
														<MCAComponent
															questionIndex={index}
															question={question?.question}
															point={question?.point}
														/>
													)}
													{question?.question?.type === 'checkbox' && (
														<CheckBoxComponent
															questionIndex={index}
															question={question?.question}
															point={question?.point}
														/>
													)}
													{question?.question?.type === 'shortAns' && (
														<ShortAnsComponent
															questionIndex={index}
															question={question?.question}
															point={question?.point}
														/>
													)}
													{question?.question?.type === 'paragraph' && (
														<ParagraphComponent
															questionIndex={index}
															question={question?.question}
															point={question?.point}
														/>
													)}

													{/* {!teacherView && ( */}
													{!isInstructor() && (
														<Row className='no-print' style={{ marginTop: 15 }}>
															<Col xs={23} md={11} offset={1}>
																<Space>
																	<Button
																		onClick={() => {
																			setQuestionObject({
																				questionObject: question?.question,
																				questionIndex: index
																			});
																			setEditModalVisible(!isEditModalVisible);
																		}}
																		icon={<EditOutlined />}
																	>
																		Edit
																	</Button>
																	<Button
																		onClick={() =>
																			handleDeleteQuestion(
																				question?.question?._id
																			)
																		}
																		danger
																		icon={<DeleteOutlined />}
																	>
																		Delete
																	</Button>
																	{question?.question?.status === 'pending' && (
																		<Button
																			type='primary'
																			icon={<CheckOutlined />}
																			onClick={() =>
																				handleQuestionStatus(
																					question?.question?._id
																				)
																			}
																		>
																			Approve
																		</Button>
																	)}
																</Space>
															</Col>
														</Row>
													)}
													{/* )} */}
												</Col>
											</Row>
											<Divider />
										</div>
									);
								})}
						</Card>
					</Col>
				</Row>
			</Content>
		</React.Fragment>
	);
};

export default ExamDetails;
