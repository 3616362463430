import React from 'react';
import { Card, Image,Button } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { bucket_url } from '../../constants/constString';
import DummyImage from '../../assets/images/dummy-image.png';
import EditGroupModal from './EditGroupModal';
import {useHistory} from 'react-router-dom';

const { Meta } = Card;

const GroupCard = props => {
	const { data, selectedCourse } = props;
	const [isEditModalVisible, setEditModalVisible] = React.useState(false);
	const [isSubject, setIsSubject] = React.useState(false);
	const history = useHistory();
	const match = useRouteMatch();

	return (
		<div>
			<EditGroupModal
				visible={isEditModalVisible}
				onCancel={() => setEditModalVisible(!isEditModalVisible)}
				group={{
					_id: data?._id,
					name: data?.name,
					image: data?.image,
				}}
				isSubject={isSubject}
			/>
			{(
				<Button
					className='course-edit-btn'
					type='primary'
					shape='circle'
					icon={<EditOutlined />}
					onClick={() => setEditModalVisible(!isEditModalVisible)}
				/>
			)}
			<Card
				onClick={() =>
				{
					localStorage.setItem('selectedGroup', JSON.stringify(data))
					history.push(`${match.url}/${data.courseId}/${data._id}`)
				}
				}
				className='admin-group-card'
				style={{ height: '100%' }}
				cover={
					!!data && (
						<Image
							height='250px'
							alt='image'
							placeholder
							fallback={DummyImage}
							src={
								bucket_url + data?.image ||
								'https://gw.alipayobjects.com/zos/rmsportal/JiqGstEfoWAOHiTxclqi.png'
							}
						/>
					)
				}
			>
				<Meta
					title={<strong>{data?.name || 'Title'}</strong>}
					description={`Total Students ${data?.totalStudents}`}
				/>
			</Card>
		</div>
	);
};

export default GroupCard;
