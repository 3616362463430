
import React, { useState } from "react";
import { deleleExpTables } from "../../stateManager/reducers/questionSlice";
import { useDispatch } from "react-redux";
import { Divider } from "antd";
import { shouldUseKatex } from "../../constants/constFunction";
import MathInput from "../Common/MathJax";
const ExplanationTables = ({ questionIndex, initialTables, isEditable = false }) => {
    
    console.log('initialTables', questionIndex, initialTables);
    const dispatch = useDispatch();
  
    // Function to delete a table
    const deleteTable = (indexToDelete) => {
      const updatedTables = initialTables.filter(
        (_, tableIndex) => tableIndex !== indexToDelete
      );
      dispatch(deleleExpTables({ index: questionIndex, tableIndex: indexToDelete }));
     
    };
  
    if (!Array.isArray(initialTables) || initialTables.length === 0) {
      // return <p>No explanation tables available.</p>;
      return <></>
    }
  
    return (
        <div>
          {initialTables.map((tableData, tableIndex) => {
            // Separate the header row and body rows
            const [headerRow, ...bodyRows] = tableData;
      
            return (
              <div key={`table-${tableIndex}`} style={{ marginBottom: "20px" }}>
                <h3>
                 <Divider orientation="left"></Divider>
                  {isEditable && (
                    <button
                      onClick={() => deleteTable(tableIndex)}
                      style={{
                        marginLeft: "10px",
                        padding: "5px 10px",
                        color: "white",
                        background: "red",
                        border: "none",
                        cursor: "pointer"
                      }}
                    >
                      Delete
                    </button>
                  )}
                </h3>
                <table border="1" cellPadding="10" cellSpacing="0">
                  <thead>
                    <tr>
                      {headerRow &&
                        Object.values(headerRow).map((header, colIndex) => (
                          <th key={`header-${colIndex}`}>{
                            header && (
                              (shouldUseKatex(header) > -1 ? (
                                <MathInput mathItem={header} />
                              ) : (
                                header
                              ))
                            )
                          }</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {bodyRows.map((row, rowIndex) => (
                      <tr key={`row-${rowIndex}`}>
                        {Object.values(row).map((cell, cellIndex) => (
                          <td key={`cell-${rowIndex}-${cellIndex}`}>{
                            cell && (
                              (shouldUseKatex(cell) > -1 ? (
                                <MathInput mathItem={cell} />
                              ) : (
                                cell
                              ))
                            )
                          }</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            );
          })}
        </div>
      );
      
  };

    export default ExplanationTables;