import React, { useState } from 'react';
import { Modal, Form, Input, Upload, Button, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { bucket_url } from '../../constants/constString';
import { useDispatch } from 'react-redux';
import { patchFileRequest, signedUrl as signedUrlRequest } from '../../stateManager/reducers/questionSlice';
import { updateGroupNameImage } from '../../stateManager/reducers/groupSlice';



const EditGroupModal = ({ visible, onCancel, onOk, group }) => {
  const [form] = Form.useForm();
  console.log('group', group);
  const [fileList, setFileList] = useState([]);
    const dispatch = useDispatch();


  const handleFinish = async (values) => {
    
    try {
        if (fileList.length === 0) {
            
           await dispatch(updateGroupNameImage({id: group._id, name: values.name, image: group.image}));
        return;
        }
        console.log('fileList', fileList);
        const signResp = await dispatch(signedUrlRequest(fileList[0].originFileObj.type));
        var { key, signedUrl } = signResp.payload?.data;
        const patchResp = await dispatch(
            patchFileRequest({ signedUrl, file: fileList[0].originFileObj })
        );
        await dispatch(updateGroupNameImage({id: group._id, name: values.name, image: key}));
    } catch (error) {
        console.log(error);
    }
  };

  return (
    <Modal
      title="Edit Group"
      open={visible}
      onCancel={onCancel}
      onOk={() => form.submit()}
      okText="Save"
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: group.name,
          image: group.image,
        }}
        onFinish={handleFinish}
      >
        <Form.Item
          name="name"
          label="Group Name"
          rules={[{ required: true, message: 'Please enter the group name' }]}
        >
          <Input placeholder="Enter group name" />
        </Form.Item>

        <Form.Item label="Group Image">
          <Upload
            listType="picture"
            fileList={fileList}
            onChange={({ fileList: newFileList }) => setFileList(newFileList)}
            maxCount={1}
            beforeUpload={() => false} // Prevent automatic upload
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          {group.image && !fileList.length && (
            <div style={{ marginTop: 8 }}>
              <img src={bucket_url + group.image} alt="Group" style={{ width: '100px' }} />
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditGroupModal;
