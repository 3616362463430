import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toastr from "toastr";
import { instance } from "../../constants/constString";
import { getExamResultRequest } from "./studentAuthSlice";
import { removeGroupFromExamDeleteModal } from "./examSlice";

const initialState = {
  groupList: [],
  studentsListByGroup: [],
  groupSubjects: [],
  searchName: "",
  searchId: "",
  searchPhone: "",
  filterBranch: null,
  filterCourse: null,
  filterSession: null,
  selectedGroup: undefined,
  examDataForResult: {},
  examResult: [],
  meritListFile: [],
  status: "idle",
  error: null,
};

export const getGroup = createAsyncThunk(
  "groups/getGroup",
  async ({ session, courseId }) => {
    try {
      const response = await instance.get(`/group/${session}/${courseId}`);
      return response;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addGroup = createAsyncThunk("groups/addGroup", async (data) => {
  try {
    const response = await instance.post("/group", data);
    return response.data;
  } catch (error) {
    toastr.error(error.response.data.errors.title);
    return Promise.reject(error);
  }
});

export const updateGroupNameImage = createAsyncThunk(
  "groups/updateGroupNameImage",
  async ({ id, name, image }) => {
    try {
      const response = await instance.patch(`/group/update-group-name-image`, {
        id,
        name,
        image,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const assignSubjectsToGroup = createAsyncThunk(
  "group/assign-subjects",
  async (data) => {
    try {
      const res = await instance.post("qa/add-subject-to-group", data);
      return res.data;
    } catch (error) {
      toastr.error(error.message);
      return error;
    }
  }
);

export const getGroupSubjects = createAsyncThunk(
  "group/getGroupSubjects",
  async ({ groupId }) => {
    try {
      const response = await instance.get(
        `/qa/get-subject-by-group/${groupId}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const studentStatusUpdateRequest = createAsyncThunk(
  "students/studentStatusUpdateRequest",
  async ({ studentId, data }) => {
    try {
      const response = await instance.patch(
        `/student/status/${studentId}`,
        data
      );
      toastr.success("Student status successfully");
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const addStudentsToGroup = createAsyncThunk(
  "groups/addStudents",
  async ({ groupId, usernames, studentData }) => {
    try {
      const response = await instance.patch(`/group/${groupId}/add-student`, {
        usernames,
      });
      if (response.status === 200 && response?.data?.data?.title) {
        toastr.success(response?.data?.data?.title);
      }
      return { data: response.data, studentData };
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const removeStudentsFromGroup = createAsyncThunk(
  "groups/removeStudents",
  async ({ groupId, data }) => {
    try {
      const response = await instance.patch(
        `/group/remove-student/${groupId}`,
        data
      );
      toastr.success("Studentd removed from group successfully");
      return data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const getStudentsByGroup = createAsyncThunk(
  "groups/getStudents",
  async ({ groupId }) => {
    try {
      const response = await instance.get(`/group/all/students/${groupId}`);
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const exportStudentsByGroup = createAsyncThunk(
  "group/exportStudentsByGroup",
  async ({ groupId }) => {
    try {
      const response = await instance.get(`/student/export/group/${groupId}`);
      window.open(response.data?.data?.S3.Location);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const accessStatusChangeByGroup = createAsyncThunk(
  "groups/accessStatusChange",
  async ({ groupId, chapterId, lectureId, status, questionSolveId }) => {
    try {
      const response = await instance.patch(
        `/group/${groupId}/access-status?status=${status}${
          chapterId ? "&chapterId=" + chapterId : ""
        }${lectureId ? "&lectureId=" + lectureId : ""}${
          questionSolveId ? "&questionSolveId=" + questionSolveId : ""
        }`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addAccessToGroupById = createAsyncThunk(
  "groups/addAccessToGroupById",
  async ({ groupId, chapterId, lectureId, questionSolveId }) => {
    try {
      const response = await instance.patch(
        `${
          lectureId ? "/lecture" : chapterId ? "/chapter" : "question-solve"
        }/${groupId}/add-access/${
          lectureId ? lectureId : chapterId ? chapterId : questionSolveId
        }`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addExamToGroup = createAsyncThunk(
  "groups/addExamToGroup",
  async ({ groupId, data, sscMultiplier, hscMultiplier }) => {
    try {
      const response = await instance.patch(
        `/group/${groupId}/add-exam?${
          sscMultiplier ? "ssc=" + sscMultiplier : ""
        }${hscMultiplier ? "&hsc=" + hscMultiplier : ""}`,
        data
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const updateExamFromGroup = createAsyncThunk(
  "groups/updateExam",
  async ({ groupId, examId, data, deleteModal }, { getState, dispatch }) => {
    try {
      const response = await instance.patch(
        `/group/${groupId}/update/${examId}`,
        data
      );
      if (response.data.status === "200") {
        toastr.success("Exam updated successfully!");
      }

      if (deleteModal) {
        dispatch(removeGroupFromExamDeleteModal(groupId));
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getExamResultById = createAsyncThunk(
  "group/getExamResult",
  async ({ examId, lastId, groupId }) => {
    try {
      const response = await instance.get(
        `/exam/result/${examId}/group-id/${groupId}${
          lastId ? "?lastId=" + lastId : ""
        }`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const publishExamResult = createAsyncThunk(
  "group/publishExamResult",
  async ({ examId, studentId, groupId }) => {
    try {
      const response = await instance.patch(
        `/exam/publish/${examId}/student/${studentId}/group/${groupId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const publishTotalExamResult = createAsyncThunk(
  "group/publishTotalExamResult",
  async ({ examId, groupId, data }) => {
    try {
      const response = await instance.patch(
        `/exam/publish-all/${examId}/group/${groupId}`,
        data,
        { timeout: 1000 * 60 * 5 }
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getExamById = createAsyncThunk(
  "group/getExamById",
  async ({ examId, groupId }) => {
    try {
      const response = await instance.get(
        `/exam/id/${examId}/groupId/${groupId}`
      );
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const markAnswer = createAsyncThunk(
  "group/markAnswer",
  async ({ examId, data, groupId }) => {
    try {
      const response = await instance.patch(
        `/exam/mark-answer/${examId}/group/${groupId}`,
        data
      );
      console.log("markAnswer");
      if (response.status === 200) {
        toastr.success("Mark submitted successfully!");
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const generateMeritList = createAsyncThunk(
  "groups/generateMeritList",
  async ({ data, group }) => {
    try {
      const response = await instance.get(`exam/aggregate?${data}${group}`);
      if (response.status === 200) {
        toastr.success(response.data?.data?.title);
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const getFileRequest = createAsyncThunk("groups/getFile", async () => {
  try {
    const response = await instance.get("file/all");
    return response.data;
  } catch (error) {
    Promise.reject(error);
  }
});

export const getSubmissionProcessStatus = createAsyncThunk(
  "groups/getSubmissionProcessStatus",
  async ({ groupId, examId }) => {
    try {
      const response = await instance.get(
        `/exam/process-submission/${examId}/group/${groupId}`
      );
      return response.data;
    } catch (error) {
      console.log(error.response);
      return Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "groups",
  initialState: initialState,
  reducers: {
    getGroupList: (state) => {
      return state.groupList;
    },
    setSearchName: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetFilters: (state, action) => {
      state.searchId = initialState.searchId;
      state.searchName = initialState.searchName;
      state.searchPhone = initialState.searchPhone;
      state.filterSession = initialState.filterSession;
      state.filterCourse = initialState.filterCourse;
      state.filterBranch = initialState.filterBranch;
    },
    setSelectedGroup: (state, action) => {
      state.selectedGroup = action.payload;
    },
    addMeritListFile: (state, action) => {
      state.meritListFile = state.meritListFile.concat(action.payload);
    },
    setData: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    resetData: (state, action) => {
      const { key } = action.payload;
      state[key] = initialState[key];
    },
    deleteStudentFromGroup: (state, action) => {
      const { usernames } = action.payload.data;
      const temp = { ...state.studentsListByGroup };
      state.studentsListByGroup = temp.filter((item) => {
        for (let i = 0; i < usernames.length; i++) {
          if (item.username !== usernames[i]) {
            return item;
          }
        }
      });
    },
  },
  extraReducers: {
    [getGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [getGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groupList = action.payload?.data?.data;
    },
    [getGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [addGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      let newArr = [];
      if (state.groupList.length > 0) {
        if (state.groupList[0].courseId === action.payload?.data?.courseId) {
          newArr.push(action.payload?.data);
        }
      } else {
        newArr.push(action.payload?.data);
      }
      state.groupList = state.groupList.concat(newArr);
    },
    [addGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getStudentsByGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [getStudentsByGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsListByGroup = action.payload?.data;
    },
    [getStudentsByGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addStudentsToGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [addStudentsToGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { studentData, data } = action.payload;
      const temp = [...state.studentsListByGroup];
      const newArr = [];
      if (!!studentData) {
        for (let i = 0; i < studentData.length; i++) {
          let found = false;
          const element1 = studentData[i];
          for (let j = 0; j < temp.length; j++) {
            const element2 = temp[j];
            if (element1._id === element2._id) {
              found = true;
              break;
            }
          }
          if (!found) {
            newArr.push(element1);
          }
        }
        if (!!data && data?._doc) {
          state.groupList = state.groupList.map((item) =>
            item._id === data?._doc?._id ? data?._doc : item
          );
        }
      }
      state.studentsListByGroup = [...newArr, ...state.studentsListByGroup];
      // state.groupList = data &&
    },
    [addStudentsToGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [accessStatusChangeByGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [accessStatusChangeByGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedGroup = action.payload?.data;
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify(state.selectedGroup)
      );
    },
    [accessStatusChangeByGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addAccessToGroupById.pending]: (state, action) => {
      state.status = "loading";
    },
    [addAccessToGroupById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedGroup = action.payload?.data;
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify(state.selectedGroup)
      );
    },
    [addAccessToGroupById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addExamToGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [addExamToGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedGroup = action.payload?.data;
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify(state.selectedGroup)
      );
    },
    [addExamToGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateExamFromGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateExamFromGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.selectedGroup = action.payload?.data;
      localStorage.setItem(
        "selectedGroup",
        JSON.stringify(state.selectedGroup)
      );
    },
    [updateExamFromGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getExamById.pending]: (state, action) => {
      state.status = "loading";
    },
    [getExamById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examDataForResult = action.payload?.data;
    },
    [getExamById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getExamResultById.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.examResult = state.examResult.concat(
        action.payload?.data?.filter((item) => !!item?.studentId)
      );
    },
    [getExamResultById.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [markAnswer.fulfilled]: (state, action) => {
      state.status = "succeeded";
      let index = state.examResult.findIndex(
        (item) => item?._id === action.payload.data?._id
      );
      if (index > -1) {
        state.examResult[index].answers = action.payload.data?.answers;
        state.examResult[index].isAssessed = true;
      }
    },
    [publishExamResult.pending]: (state, action) => {
      state.status = "loading";
    },
    [publishExamResult.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const data = action?.payload?.data;
      const tempResult = [...state.examResult];
      const newResult = tempResult.map((item, index) =>
        item?._id === data?._id
          ? { ...data, studentId: { ...item?.studentId } }
          : item
      );
      state.examResult = [...newResult];
    },
    [publishExamResult.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [publishTotalExamResult.pending]: (state, action) => {
      state.status = "loading";
    },
    [publishTotalExamResult.fulfilled]: (state, action) => {
      state.status = "succeeded";
      console.log("stateeee", state.selectedGroup);
      if (state.selectedGroup) {
        state.selectedGroup.exams = state.selectedGroup?.exams.map((item) =>
          item.examId !== action.payload?.data[0]?.examId
            ? item
            : { ...item, status: "resultPublished" }
        );
      }

      const data = action?.payload?.data;
      const tempResult = [...state.examResult];
      const newResult = tempResult.map((item, index) =>
        item?._id === data[index]?._id
          ? { ...data[index], studentId: { ...item?.studentId } }
          : item
      );
      state.examResult = [...newResult];
    },
    [publishTotalExamResult.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getFileRequest.pending]: (state, action) => {
      state.status = "failed";
    },
    [getFileRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.meritListFile = action.payload?.data;
    },
    [getFileRequest.rejected]: (state, action) => {
      state.status = "failed";
    },
    [removeStudentsFromGroup.pending]: (state, action) => {
      state.status = "failed";
    },
    [removeStudentsFromGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { usernames } = action.payload;
      const temp = [...state.studentsListByGroup];
      state.studentsListByGroup = temp.filter(
        (item) => usernames.indexOf(item.username) === -1
      );
    },
    [removeStudentsFromGroup.rejected]: (state, action) => {
      state.status = "failed";
    },
    [studentStatusUpdateRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [studentStatusUpdateRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.studentsListByGroup = state.studentsListByGroup?.map((item) =>
        item._id === action.payload?.data?._id ? action.payload?.data : item
      );
    },
    [studentStatusUpdateRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [assignSubjectsToGroup.pending]: (state, action) => {
      state.status = "loading";
    },
    [assignSubjectsToGroup.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groupSubjects = action.payload.data.subjects;
      toastr.success("Subjects assigned to group successfully");
    },
    [assignSubjectsToGroup.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getGroupSubjects.pending]: (state, action) => {
      state.status = "loading";
    },
    [getGroupSubjects.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groupSubjects = action.payload?.data.subjects;
    },
    [getGroupSubjects.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateGroupNameImage.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateGroupNameImage.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.groupList = state.groupList.map((item) =>
        item._id === action.payload.data._id ? action.payload.data : item
      );
    },
    [updateGroupNameImage.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [generateMeritList.pending]: (state, action) => {
      state.status = "loading";
    },
  },
});

export const filteredStudentList = (state) => {
  const {
    searchName,
    searchId,
    searchPhone,
    filterBranch,
    filterCourse,
    filterSession,
    groupSubjects,
  } = state.groups;

  return (
    state.groups?.studentsListByGroup &&
    state.groups?.studentsListByGroup?.filter(
      (item) =>
        (filterBranch ? item?.branch?.includes(filterBranch) : true) &&
        (filterCourse?.length > 0
          ? item?.courses?.indexOf(filterCourse) >= 0
          : true) &&
        item?.name?.toLowerCase()?.includes(searchName) &&
        item?.sid?.toLowerCase()?.includes(searchId) &&
        item?.username?.toLowerCase()?.includes(searchPhone) &&
        (filterSession ? item?.session?.includes(filterSession) : true)
    )
  );
};

export const {
  getGroupList,
  setSelectedGroup,
  addMeritListFile,
  setData,
  resetData,
  setSearchName,
  resetFilters,
} = slice.actions;

export default slice.reducer;
