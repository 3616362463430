import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import toastr from "toastr";
import arrayMove from "array-move";
import { instance } from "../../constants/constString";

const initialState = {
  courseList: [],
  subjectList: [],
  lectureList: [],
  chapterList: [],
  questionSolveList: [],
  status: "idle",
  error: null,
};

export const getCourses = createAsyncThunk("courses/getCourses", async () => {
  try {
    const response = await instance.get("/course");
    return response.data;
  } catch (error) {
    toastr.error(error.response.data.errors.title);
    return Promise.reject(error);
  }
});

export const addCourse = createAsyncThunk(
  "courses/addCourse",
  async ({ name, description, image, session }) => {
    try {
      const response = await instance.post("/course", {
        name,
        description,
        image,
        session,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const updateCourseRequest = createAsyncThunk(
  "courses/updateCourseRequest",
  async ({ courseId, data }) => {
    try {
      const response = await instance.patch(`/course/${courseId}`, data);
      return response.data;
    } catch (error) {
      if (error?.response?.data?.errors?.title) {
        toastr.error(error?.response?.data?.errors?.title);
      }
      Promise.reject(error);
    }
  }
);

export const getSubjectByCourse = createAsyncThunk(
  "courses/getSubjectByCourse",
  async (courseId) => {
    try {
      const response = await instance.get(`/subject/by-course-id/${courseId}`);
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export const addSubjectByCourse = createAsyncThunk(
  "courses/addSubject",
  async ({ name, courseId, image, description }) => {
    try {
      const response = await instance.post("/subject", {
        name,
        courseId,
        image,
        description,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const editSubjectRequest = createAsyncThunk(
  "courses/editSubject",
  async ({ data, subjectId }) => {
    try {
      const response = await instance.patch(`/subject/${subjectId}`, data);
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const deleteSubjectRequest = createAsyncThunk(
  "courses/deleteSubject",
  async ({ subjectId }) => {
    try {
      const response = await instance.delete(`/subject/${subjectId}`);
      return { data: response.data, subjectId };
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const getLectureBySubject = createAsyncThunk(
  "courses/getLectureBySubject",
  async ({ subjectId }) => {
    try {
      const response = await instance.get(
        `/lecture/by-subject-id/${subjectId}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addLectureBySubject = createAsyncThunk(
  "courses/addLectureBySubject",
  async ({ name, subjectId, description }) => {
    try {
      const response = await instance.post("/lecture", {
        name,
        subjectId,
        description,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const getChapterBySubject = createAsyncThunk(
  "courses/getChapterBySubject",
  async ({ subjectId }) => {
    try {
      const response = await instance.get(
        `/chapter/by-subject-id/${subjectId}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addChapterBySubject = createAsyncThunk(
  "courses/addChapterBySubject",
  async ({ name, subjectId, description }) => {
    try {
      const response = await instance.post("/chapter", {
        name,
        subjectId,
        description,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const getQuestionSolveBySubject = createAsyncThunk(
  "courses/getQuestionSolveBySubject",
  async ({ subjectId }) => {
    try {
      const response = await instance.get(
        `/question-solve/by-subject-id/${subjectId}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const addQuestionSolveBySubject = createAsyncThunk(
  "courses/addQuestionSolveBySubject",
  async ({ name, subjectId, description }) => {
    try {
      const response = await instance.post("/question-solve", {
        name,
        subjectId,
        description,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const editChapterLecture = createAsyncThunk(
  "courses/editChapterLecture",
  async ({ name, subjectId, type, typeId }) => {
    try {
      const response = await instance.patch(`/${type}/${typeId}/update`, {
        name,
        subjectId,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const deleteChapterLecture = createAsyncThunk(
  "courses/deleteChapterLecture",
  async ({ name, subjectId, type, typeId }) => {
    try {
      const response = await instance.delete(`/${type}/${typeId}/delete`, {
        name,
        subjectId,
      });
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);
export const createQuestionSolveFromChapter = createAsyncThunk(
  "courses/createQuestionSolveFromChapter",
  async ({ chapterId }) => {
    try {
      const response = await instance.get(
        `/chapter/createQuestionSolveFromChapter/${chapterId}`
      );
      return response.data;
    } catch (error) {
      toastr.error(error.response.data.errors.title);
      return Promise.reject(error);
    }
  }
);

export const reorderChapterLecture = createAsyncThunk(
  "courses/reorderChapterLecture",
  async ({ subjectId, data }) => {
    try {
      const response = await instance.patch(
        `/subject/${subjectId}/reorder/lecture-chapter`,
        data
      );
      return response.data;
    } catch (error) {
      Promise.reject(error);
    }
  }
);

const slice = createSlice({
  name: "courses",
  initialState: initialState,
  reducers: {
    getCoursesList: (state) => {
      return state.courseList;
    },
    setReorderData: (state, action) => {
      const { oldIndex, newIndex, dataSource, type } = action.payload;
      if (oldIndex !== newIndex) {
        const newData = arrayMove(
          [].concat(dataSource),
          oldIndex,
          newIndex
        ).filter((el) => !!el);
        state.lectureList = type === "lecture" ? newData : state.lectureList;
        state.chapterList = type === "chapter" ? newData : state.chapterList;
        state.questionSolveList =
          type === "question-solve" ? newData : state.questionSolveList;
      }
    },
    updateChapterLecture: (state, action) => {
      const { name, id, type } = action.payload;
      state.chapterList = state.chapterList.map((item) =>
        type === "chapter" && item._id === id ? { ...item, name: name } : item
      );
      state.lectureList = state.lectureList.map((item) =>
        type === "lecture" && item._id === id ? { ...item, name: name } : item
      );
      state.questionSolveList = state.questionSolveList.map((item) =>
        type === "question-solve" && item._id === id
          ? { ...item, name: name }
          : item
      );
    },
    deleteChapterLectureLocal: (state, action) => {
      const { index, type } = action.payload;
      if (type === "chapter") {
        const temp = [...state.chapterList];
        temp.splice(index, 1);
        state.chapterList = temp;
      } else if (type === "lecture") {
        const temp = [...state.lectureList];
        temp.splice(index, 1);
        state.lectureList = temp;
      } else {
        const temp = [...state.questionSolveList];
        temp.splice(index, 1);
        state.questionSolveList = temp;
      }
    },
  },
  extraReducers: {
    [getCourses.pending]: (state, action) => {
      state.status = "loading";
    },
    [getCourses.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.courseList = action.payload?.data;
    },
    [getCourses.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addCourse.pending]: (state, action) => {
      state.status = "loading";
    },
    [addCourse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.courseList = state.courseList.concat(action.payload?.data);
    },
    [addCourse.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [updateCourseRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [updateCourseRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const data = action.payload?.data;
      const newData = state.courseList.map((item, index) =>
        item._id === data?._id ? data : item
      );
      state.courseList = [...newData];
    },
    [updateCourseRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getSubjectByCourse.pending]: (state, action) => {
      state.status = "loading";
    },
    [getSubjectByCourse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.subjectList = action.payload?.data;
    },
    [getSubjectByCourse.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addSubjectByCourse.pending]: (state, action) => {
      state.status = "loading";
    },
    [addSubjectByCourse.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.subjectList = state.subjectList.concat(
        action.payload?.data?.subject
      );
    },
    [addSubjectByCourse.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [editSubjectRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [editSubjectRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const data = action.payload?.data;
      const newData = state.subjectList.map((item, index) =>
        item._id === data?._id ? data : item
      );
      state.subjectList = [...newData];
    },
    [editSubjectRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteSubjectRequest.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteSubjectRequest.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const { subjectId } = action.payload;
      const tempSubjectList = [...state.subjectList];
      state.subjectList = tempSubjectList.filter(
        (item) => item?._id !== subjectId
      );
    },
    [deleteSubjectRequest.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getLectureBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getLectureBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.lectureList = action.payload?.data?.lectures;
    },
    [getLectureBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addLectureBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [addLectureBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.lectureList = state.lectureList.concat(
        action.payload?.data?.lecture
      );
    },
    [addLectureBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getChapterBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getChapterBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.chapterList = action.payload?.data?.chapters;
    },
    [getChapterBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addChapterBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [addChapterBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.chapterList = state.chapterList.concat(
        action.payload?.data?.chapter
      );
    },
    [addChapterBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [getQuestionSolveBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [getQuestionSolveBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.questionSolveList = action.payload?.data?.questionSolves;
    },
    [getQuestionSolveBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [addQuestionSolveBySubject.pending]: (state, action) => {
      state.status = "loading";
    },
    [addQuestionSolveBySubject.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.questionSolveList = state.questionSolveList.concat(
        action.payload?.data?.questionSolve
      );
    },
    [addQuestionSolveBySubject.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [editChapterLecture.pending]: (state, action) => {
      state.status = "loading";
    },
    [editChapterLecture.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [editChapterLecture.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
    [deleteChapterLecture.pending]: (state, action) => {
      state.status = "loading";
    },
    [deleteChapterLecture.fulfilled]: (state, action) => {
      state.status = "succeeded";
    },
    [deleteChapterLecture.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const {
  getCoursesList,
  setReorderData,
  updateChapterLecture,
  deleteChapterLectureLocal,
} = slice.actions;

const status = (state) => state.courses.status;
const courseList = (state) => state.courses.courseList;
const subjectList = (state) => state.courses.subjectList;

export { status, courseList, subjectList };

export default slice.reducer;
