import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Tabs, Select, DatePicker, Form, Input, Checkbox } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import ExamListCardComponent from './ExamListCard.component';
import { isInstructor } from '../../constants/constFunction';
import ExamDeleteModal from './ExamDeleteModal';
import { LOCAL_KEY } from '../../constants/constString';
import { getLocalStorageData, updateLocalStorage } from '../../constants/constFunction';
import { setIsSegmentedExam, resetQuestionsForExam, addSegmentedExamSubjectIds } from '../../stateManager/reducers/examSlice';
import moment from 'moment';
const { TabPane } = Tabs;
const { Option } = Select;
const { RangePicker } = DatePicker;

const ExamListCard = props => {
	const history = useHistory();
	const { courseId, examList } = props;
	const [selectedSubject, setSelectedSubject] = useState(null);
	const [ selectedTab, setSelectedTab ] = useState('live');
	const [dateRange, setDateRange] = useState([]);
	const [ search, setSearch ] = useState('');
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);
	const isSegmentedExam = useSelector(state => state.exams.isSegmentedExam);
	console.log('isSegmentedExam', isSegmentedExam);
	const dispatch = useDispatch();
	
	const subjectList = useSelector(state => state.courses.subjectList);

	const handleCancelDelete = (e) => {
		console.log(e);
		setDeleteModalVisible(false);
	}

	useEffect(() => {
		if (isSegmentedExam) {
			dispatch(addSegmentedExamSubjectIds([]))
		}

	}, [isSegmentedExam])

	useEffect(() => {
		const { selectedSubject, date, search } = getLocalStorageData(LOCAL_KEY.EXAM_KEY);
		if (selectedSubject) {
			setSelectedSubject(selectedSubject)
		}
		if (date) {
			console.log('date', date);
			const range = date.map(item => moment(item));
			setDateRange(range)
			props.handleDateWiseFilter(range)
		}
		if (search) {
			setSearch(search)
			props.handleSearchByName(search)
		}
	}, [])

	// useEffect(() => {

	// 	console.log('history updated', isSegmentedExam);
	// 	setIsSegmentedExam(false)
	// }, [history])

	// useEffect(() => {
	// 	if (publishedGrups.length > 0) {
	// 		toggleDeleteModal()
	// 	}
	// }, [publishedGrups])

	const toggleDeleteModal = () => {
		setDeleteModalVisible(!deleteModalVisible);
	}

	return (
		<Row>
			<Col xs={24}>
				<ExamDeleteModal
					isModalVisible={deleteModalVisible}
					onCancel={handleCancelDelete}
					toggleDeleteModal={toggleDeleteModal}
				/>
				<Tabs
					className='CoursWiseExamListTab'
					defaultActiveKey='live'
					tabBarExtraContent={
						<div
							style={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							{!isInstructor() && (
								<React.Fragment>
									<Checkbox
										checked={isSegmentedExam}
										onChange={()=>{
											updateLocalStorage(LOCAL_KEY.EXAM_KEY, 'isSegmentedExam', !isSegmentedExam);
											dispatch(setIsSegmentedExam(!isSegmentedExam))
										}}
									>
										Segmented Exam
									</Checkbox>
									
									{
										!isSegmentedExam && (
											<Select
										value={selectedSubject}
										placeholder='Select Subject'
										allowClear
										onChange={value => {
											updateLocalStorage(LOCAL_KEY.EXAM_KEY, 'selectedSubject', value);
											setSelectedSubject(value)
										}}
										style={{ width: 150 }}
									>
										{subjectList &&
											subjectList.map((item, index) => (
												<Option key={index} value={item?._id}>
													{item?.name}
												</Option>
											))}
									</Select>
										)
									}
									
									<Button
										disabled={!selectedSubject && !isSegmentedExam}
										onClick={() =>
										{
											dispatch(resetQuestionsForExam())
											if (isSegmentedExam) {
												history.push(`/add-segmented-exam/${courseId}`)
											} else {
												history.push(`/add-exam/${courseId}/${selectedSubject}`)
											}

										}

										}
										type='primary'
										icon={<PlusOutlined />}
										style={{ margin: 5 }}
									>
										Add Exam
									</Button>
								</React.Fragment>
							)}
							<Form>
								<Form.Item style={{ margin: 5 }}>
									<Input
										placeholder='Search Exam'
										value={search}
										onChange={e => {
											updateLocalStorage(LOCAL_KEY.EXAM_KEY, 'search', e.target.value);
											setSearch(e.target.value)
											props.handleSearchByName(e.target.value)
										}}
									/>
								</Form.Item>
							</Form>
							<RangePicker
								style={{ margin: 5 }}
								value={dateRange}
								onChange={range => {
									console.log('range', range);
									updateLocalStorage(LOCAL_KEY.EXAM_KEY, 'date', range);
									setDateRange(range)
									props.handleDateWiseFilter(range);
								}}
							/>
						</div>
					}
				>
					<TabPane
						tab={
							<span style={{ fontSize: 16, fontWeight: 800 }}>Live Exam</span>
						}
						key='live'
					>
						<Row
							gutter={[20, 20]}
							style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
						>
							{examList
								.filter(
									item =>
										!item.isPracticeExam &&
										(selectedSubject
											? item?.subjectId === selectedSubject
											: true)
								)
								.map((item, index) => (
									<ExamListCardComponent
										item={item}
										{...props}
										subjectList={subjectList}
										selectedSubject={selectedSubject}
										toggleDeleteModal={toggleDeleteModal}
									/>
								))}
						</Row>
					</TabPane>
					<TabPane
						tab={
							<span style={{ fontSize: 16, fontWeight: 800 }}>
								Practice Exam
							</span>
						}
						key='practice'
					>
						<Row
							gutter={[20, 20]}
							style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
						>
							{examList
								.filter(
									item =>
										item.isPracticeExam &&
										(selectedSubject
											? item?.subjectId === selectedSubject
											: true)
								)
								.map((item, index) => (
									<ExamListCardComponent
										
										item={item}
										selectedSubject={selectedSubject}
										{...props}
										subjectList={subjectList}
										toggleDeleteModal={toggleDeleteModal}
									/>
								))}
						</Row>
					</TabPane>
				</Tabs>
			</Col>
		</Row>
	);
};

export default ExamListCard;